<template>
  <div class="cst-filter">
    <div class="cst-filter--header" data-testid="filter-header">
      {{ header }}
    </div>
    <div class="cst-filter--select-container">
      <div v-if="isCostUnit">
        <label :for="'filter-select-' + 1"
          >{{ filters[1].label }}
          <select v-model="selectedOptions[1]" :data-testid="'filter-select-' + 1" :id="'filter-select-' + 1">
            <option v-if="isProduct" :value="AllProductsValues" id="All">All</option>
            <option v-for="(option, optionIndex) in filters[1].options" :key="optionIndex" :value="option" :id="'option-' + 1 + '-' + optionIndex">
              {{ option.name }}
            </option>
          </select>
        </label>
      </div>
      <div v-if="isCompany" class="cst-filter--select-field">
        <label :for="'filter-select-' + 0"
          >{{ filters[0].label }}
          <select v-model="selectedOptions[0]" :data-testid="'filter-select-' + 0" :id="'filter-select-' + 0">
            <option v-if="isCompany" :value="AllCompaniesValue" id="All">All</option>
            <option v-for="(option, optionIndex) in filters[0].options" :key="optionIndex" :value="option" :id="'option-' + 0 + '-' + optionIndex">
              {{ option.name }}
            </option>
          </select>
        </label>
      </div>
      <div v-else class="cst-filter--select-field" v-for="(filter, index) in filters" :key="index" data-testid="filter-select-field">
        <label :for="'filter-select-' + index"
          >{{ filter.label }}
          <select v-model="selectedOptions[index]" :data-testid="'filter-select-' + index" :id="'filter-select-' + index">
            <option v-if="isProduct" :value="AllProductsValues" id="All">All</option>
            <option v-for="(option, optionIndex) in filter.options" :key="optionIndex" :value="option" :id="'option-' + index + '-' + optionIndex">
              {{ option.name }}
            </option>
          </select>
        </label>
      </div>
      <button class="cst-button cst-button-primary" data-testid="filter-submit" @click="submitFilters">Submit</button>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from "vue";
  import { useStore } from "vuex";

  interface Filter {
    label: string;
    options: Options[];
  }

  interface Options {
    id: string;
    name: string;
  }

  export default defineComponent({
    name: "FilterBar",
    props: {
      header: {
        type: String,
        required: true,
      },
      filterList: {
        type: Array as () => Filter[],
        required: true,
      },
      isProduct: {
        type: Boolean,
      },
      isCostUnit: {
        type: Boolean,
      },
      isCompany: {
        type: Boolean,
      },
    },
    setup(props, { emit }) {
      const store = useStore();
      const filters = computed(() => {
        return props.filterList.map((filter) => {
          return {
            ...filter,
            options: filter.options.slice().sort((a, b) => a.name.localeCompare(b.name)),
          };
        });
      });
      const selectedCompany = computed(() => store.getters.getSpecificCompany);
      const isCompanyRowExpanded = computed(() => store.getters.getIsCompanyRowExpanded);
      const areContactsFetched = computed(() => store.getters.getContactsFetched);
      const selectedOptions = ref<Options[]>([]);
      const AllProductsValues = {
        name: "All",
      };
      const AllCompaniesValue = {
        id: "0",
        name: "All",
      };
      if (props.isCompany) {
        selectedOptions.value[0] = AllCompaniesValue;
      }

      watch([() => selectedCompany.value, () => isCompanyRowExpanded.value], () => {
        if (selectedCompany.value && areContactsFetched.value) {
          let selectedCompanyFilter = filters.value[0].options.find((option) => option.id === selectedCompany.value?.id);
          if (selectedCompanyFilter) {
            selectedOptions.value[0] = selectedCompanyFilter;
          }
        } else {
          selectedOptions.value[0] = AllCompaniesValue;
        }
      });

      const submitFilters = () => {
        const selectedFilters: {
          label: string;
          option: string;
          name: string;
        }[] = props.filterList.reduce(
          (result, filter, index) => {
            const selectedOption = selectedOptions.value[index];

            if (selectedOption) {
              result.push({
                label: filter.label,
                option: selectedOption.id,
                name: selectedOption.name,
              });
            }

            return result;
          },
          [] as { label: string; option: string; name: string }[]
        );

        if (selectedFilters.length > 0) {
          emit("submit", selectedFilters);
        }
      };

      return {
        filters,
        submitFilters,
        selectedOptions,
        AllProductsValues,
        AllCompaniesValue,
      };
    },
  });
</script>
