<template>
  <div class="v-filter-companies__wrapper" data-testid="companies-wrapper">
    <div class="v-filter-companies__filter-wrapper">
      <FilterBar :header="header" :filterList="filters" :isCompany="true" @submit="handleFilterSubmit" v-if="areFiltersFetched" />
    </div>
    <div class="v-filter-companies__products-wrapper">
      <div class="v-filter-products__summary-overview">
        <div class="v-filter-products__summary-overview--title" data-testid="summary-overview">
          {{ selectedCompany ? selectedCompany : "All" }}
          {{ selectedCompany ? "Company Contacts" : "Companies" }}
        </div>
      </div>
      <div class="v-filter-companies__toggle-view">
        <div class="v-filter-companies__toggle-view--sort">
          <label
            >Sort by:
            <select v-model="sortingOption" data-testid="sortOptions">
              <option value="default" selected>Name ↓</option>
              <option value="name-ascending">Name ↑</option>
              <option v-if="!selectedCompany" value="city-descending">City ↓</option>
              <option v-if="!selectedCompany" value="city-ascending">City ↑</option>
              <option v-if="!selectedCompany" value="country-descending">Country ↓</option>
              <option v-if="!selectedCompany" value="country-ascending">Country ↑</option>
            </select>
          </label>
        </div>
        <div>
          <C_Search :placeholder="'Search by...'" :isCompany="true" @input="filterCompaniesContacts($event)" data-testid="companies-search" />
        </div>
      </div>
      <div v-if="areCompaniesFetched && ((!areContactsFetched && !selectedCompany) || (areContactsFetched && isCompanyRowExpanded))" data-testid="companies-container">
        <div v-if="sortedCompanies.length > 0" class="c-table-overview__header">
          <div>Overview</div>
          <div></div>
          <div>Name</div>
          <div>Street</div>
          <div>House Number</div>
          <div>City</div>
          <div>Postal Code</div>
          <div>Country</div>
        </div>
        <div v-if="sortedCompanies.length > 0">
          <div v-for="(entity, index) in sortedCompanies" :key="index" data-testid="companies">
            <div class="c-table-overview__body-row">
              <div class="c-table-overview__body-row--expand-icon">
                <img :src="'./arrow-down.svg'" alt="expand down icon" @click="handleExpandRows(index, entity)" data-testid="companies-overview-button" />
              </div>
              <div class="">
                <button @click="handleOverviewClick(entity)" data-testid="companies-details-button">Details</button>
              </div>
              <div data-testid="companies-name">{{ entity.name }}</div>
              <div>{{ entity.street }}</div>
              <div>{{ entity.house_number }}</div>
              <div>{{ entity.city }}</div>
              <div>{{ entity.postal_code }}</div>
              <div>{{ entity.country }}</div>
            </div>
            <div v-if="expandedCompanyRows.includes(index) && companyContacts.length > 0 && areContactsFetched" class="c-table-overview__body-row--expanded-container" data-testid="company-contacts-overview">
              <p>
                Contacts belonging to
                <strong> {{ entity.name }}</strong>
              </p>
              <div class="c-table-overview__header c-table-overview__header--expanded">
                <div>Name</div>
                <div>Phone Number</div>
                <div>Email Address</div>
              </div>
              <div>
                <div v-for="(entity, index) in companyContacts" :key="index">
                  <div class="c-table-overview__body-row">
                    <div data-testid="contacts-name">{{ entity.name }}</div>
                    <div>{{ entity.phone }}</div>
                    <div>{{ entity.email }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="expandedCompanyRows.includes(index) && companyContacts.length === 0 && areContactsFetched" class="c-table-overview__body-row--expanded-container">
              <p>There are no contacts in this company.</p>
            </div>
            <div v-else-if="expandedCompanyRows.includes(index) && !areContactsFetched" class="cst-spinner"></div>
          </div>
        </div>
        <div v-else-if="sortedCompanies.length === 0 && allCompanies.length > 0" class="c-table-overview__body-row--empty">No companies match your search criteria!</div>
        <div v-else-if="allCompanies.length === 0" class="c-table-overview__body-row--empty">There are no companies to show!</div>
      </div>
      <div v-if="areContactsFetched && selectedCompany">
        <div v-if="sortedContacts.length > 0" class="c-table-overview__header">
          <div>Name</div>
          <div>Phone Number</div>
          <div>Email Address</div>
        </div>
        <div v-if="sortedContacts.length > 0">
          <div v-for="(entity, index) in sortedContacts" :key="index">
            <div class="c-table-overview__body-row">
              <div data-testid="company-contacts-name">{{ entity.name }}</div>
              <div>{{ entity.phone }}</div>
              <div>{{ entity.email }}</div>
            </div>
          </div>
        </div>
        <div v-else-if="sortedContacts.length === 0 && companyContacts.length > 0" class="c-table-overview__body-row--empty">No contacts match your search criteria!</div>
        <div v-else class="c-table-overview__body-row--empty">There are no contacts in this company.</div>
      </div>
      <div v-else-if="(!areContactsFetched && selectedCompany) || !areCompaniesFetched" class="cst-spinner" data-testid="v-companies-spinner"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { ref, computed, watch } from "vue";
  import { useStore } from "vuex";
  import FilterBar from "@/components/C_FilterBar.vue";
  import C_Search from "@/components/C_Search.vue";
  import { ISelectedFilter } from "@/utils/interfaces/ISelectedFilter";
  import { ICompany } from "@/utils/interfaces/ICompany";
  import { ICompanyContact } from "@/utils/interfaces/ICompanyContact";

  const { FETCH_ALL_COMPANIES, FETCH_COMPANY_FILTERS, FETCH_SPECIFIC_COMPANY } = VUEX_ACTIONS;
  const store = useStore();
  store.dispatch(FETCH_ALL_COMPANIES);
  store.dispatch(FETCH_COMPANY_FILTERS);
  store.commit("setAreCompaniesFetched", false);
  store.commit("setCompanyContacts", []);
  store.commit("setContactsFetched", false);
  store.commit("setIsCompanyRowExpanded", false);
  const header = "Filter";
  const selectedCompany = ref("");
  const sortingOption = ref("default");
  const expandedCompanyRows = ref<number[]>([]);
  const filters = computed(() => store.getters.getCompanyFilters);
  const allCompanies = computed(() => store.getters.getAllCompanies);
  const areCompaniesFetched = computed(() => store.getters.getAreCompaniesFetched);
  const companyContacts = computed(() => store.getters.getCompanyContacts);
  const areContactsFetched = computed(() => store.getters.getContactsFetched);
  const areFiltersFetched = computed(() => store.getters.getFiltersFetched);
  const isCompanyRowExpanded = computed(() => store.getters.getIsCompanyRowExpanded);

  let filteredCompanies = ref<ICompany[]>(allCompanies.value);
  const sortedCompanies = computed(() => {
    switch (sortingOption.value) {
      case "default":
        return [...filteredCompanies.value].sort((a, b) => a.name.localeCompare(b.name));
      case "name-ascending":
        return [...filteredCompanies.value].sort((a, b) => b.name.localeCompare(a.name));
      case "city-descending":
        return [...filteredCompanies.value].sort((a, b) => a.city.localeCompare(b.city));
      case "city-ascending":
        return [...filteredCompanies.value].sort((a, b) => b.city.localeCompare(a.city));
      case "country-descending":
        return [...filteredCompanies.value].sort((a, b) => a.country.localeCompare(b.country));
      case "country-ascending":
        return [...filteredCompanies.value].sort((a, b) => b.country.localeCompare(a.country));
      default:
        return filteredCompanies.value;
    }
  });

  let filteredContacts = ref<ICompanyContact[]>(companyContacts.value);
  const sortedContacts = computed(() => {
    switch (sortingOption.value) {
      case "default":
        return [...filteredContacts.value].sort((a, b) => a.name.localeCompare(b.name));
      case "name-ascending":
        return [...filteredContacts.value].sort((a, b) => b.name.localeCompare(a.name));
      default:
        return filteredContacts.value;
    }
  });

  const handleFilterSubmit = async (selectedFilter: ISelectedFilter[]) => {
    sortingOption.value = "default";
    selectedCompany.value = "";
    expandedCompanyRows.value = [];

    store.commit("setCompanyContacts", []);
    store.commit("setContactsFetched", false);
    store.commit("setIsCompanyRowExpanded", false);

    if (selectedFilter.length > 0) {
      for (const id in selectedFilter) {
        let name = selectedFilter[id].name;

        if (selectedFilter[id].label === "Companies") {
          if (name === "All") {
            store.dispatch(FETCH_ALL_COMPANIES);
          } else {
            store.dispatch(FETCH_SPECIFIC_COMPANY, selectedFilter[id].option);
            selectedCompany.value = name;
          }
        }
      }
    }
  };

  const handleOverviewClick = async (selectedEntity: ICompany) => {
    store.commit("setIsCompanyRowExpanded", false);
    expandedCompanyRows.value = [];
    selectedCompany.value = selectedEntity["name"];
    sortingOption.value = "default";
    store.dispatch(FETCH_SPECIFIC_COMPANY, selectedEntity["id"]);
  };

  const handleExpandRows = async (index: number, selectedEntity: ICompany) => {
    if (expandedCompanyRows.value.includes(index)) {
      store.commit("setCompanyContacts", []);
      store.commit("setContactsFetched", false);
      store.commit("setIsCompanyRowExpanded", false);
      expandedCompanyRows.value = [];
    } else {
      store.commit("setIsCompanyRowExpanded", true);
      expandedCompanyRows.value = [index];
      store.dispatch(FETCH_SPECIFIC_COMPANY, selectedEntity["id"]);
    }
  };

  const filterCompaniesContacts = async (event: Event) => {
    store.commit("setIsCompanyRowExpanded", false);
    expandedCompanyRows.value = [];

    const searchTerm = (event.target as HTMLInputElement).value;
    const attributesToCheckInContact = ["name", "phone", "email"];
    const attributesToCheckInCompany = ["name", "street", "house_number", "city", "postal_code", "country"];

    if (searchTerm == "" || !searchTerm) {
      filteredCompanies.value = allCompanies.value;
      filteredContacts.value = companyContacts.value;
    } else {
      if (selectedCompany.value) {
        filteredContacts.value = companyContacts.value.filter((contact: any) => {
          return attributesToCheckInContact.some((attribute) => contact[attribute] && typeof contact[attribute] === "string" && contact[attribute].toLowerCase().includes(searchTerm.toLowerCase()));
        });
      }
      if (!selectedCompany.value) {
        store.commit("setContactsFetched", false);
        filteredCompanies.value = allCompanies.value.filter((company: any) => {
          const companyMatches = attributesToCheckInCompany.some((attribute) => company[attribute] && typeof company[attribute] === "string" && company[attribute].toLowerCase().includes(searchTerm.toLowerCase()));
          const contactMatches =
            company.contacts &&
            company.contacts.some((contact: any) => {
              return attributesToCheckInContact.some((attribute) => contact[attribute] && typeof contact[attribute] === "string" && contact[attribute].toLowerCase().includes(searchTerm.toLowerCase()));
            });
          return companyMatches || contactMatches;
        });
      }
    }
  };

  watch(companyContacts, () => {
    filteredContacts.value = companyContacts.value;
  });
  watch(allCompanies, () => {
    filteredCompanies.value = allCompanies.value;
  });
  watch(sortingOption, () => {
    if (!selectedCompany.value) {
      store.commit("setIsCompanyRowExpanded", false);
      expandedCompanyRows.value = [];
      store.commit("setContactsFetched", false);
    }
  });
</script>
