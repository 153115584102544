<template>
  <RequestMessage></RequestMessage>
  <div class="createcompany__wrapper" data-testid="createcontact-wrapper">
    <div class="cst-inputbox">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">Add a new Company Contact</div>
      </div>

      <div class="cst-inputbox--body">
        <form @submit.prevent="handleCompanyContactCreation">
          <div class="cst-inputbox__form cst-inputbox__form--column-wrapper">
            <div class="createcompany__fields-row">
              <div class="cst-input-field">
                <label for="company">Company:<span style="color: red">*</span></label>
                <select class="cst-select-field" id="company" placeholder="Select Country" required v-model.trim="selectedCompany" data-testid="contactcompany-select">
                  <option disabled selected value="">Select a company</option>
                  <option v-for="company in allCompanies" :key="company.id" :value="company.id" data-testid="createcontact-companies-options">
                    {{ company.name }}
                  </option>
                </select>
              </div>
              <div class="cst-input-field">
                <label for="contact-name">Name:<span style="color: red">*</span></label>
                <input class="cst-input" type="text" id="contact-name" placeholder="Enter Contact Name" required v-model.trim="contactName" data-testid="createcontact-name" />
              </div>
              <div class="cst-input-field">
                <label for="contact-phone">Phone:</label>
                <input class="cst-input" type="tel" id="contact-phone" placeholder="Enter Phone" v-model.trim="contactPhone" data-testid="createcontact-phone" />
              </div>

              <div class="cst-input-field">
                <label for="contact-email">Email: </label>
                <input class="cst-input" type="text" id="contact-email" placeholder="Enter Email" v-model.trim="contactEmail" data-testid="createcontact-email" />
              </div>
            </div>

            <div class="cst-inputbox--flex-wrapper">
              <button class="submit-button cst-button cst-button-primary" type="submit" data-testid="createcontact-submit-button">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { ref, computed, defineProps } from "vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { ICompanyContact } from "@/utils/interfaces/ICompanyContact";

  const props = defineProps({
    fromCreateCompany: {
      type: Boolean,
      default: false,
    },
  });

  const { CREATE_COMPANY_CONTACT, FETCH_ALL_COMPANIES } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const contactName = ref("");
  const contactPhone = ref("");
  const contactEmail = ref("");
  const allCompanies = computed(() => store.getters.getAllCompanies);
  const previouslyCreatedCompany = computed(() => store.getters.getCreatedCompany);
  const selectedCompany = ref("");
  store.dispatch(FETCH_ALL_COMPANIES);

  if (props.fromCreateCompany && previouslyCreatedCompany.value) {
    selectedCompany.value = previouslyCreatedCompany.value.id;
  }

  // Route guard
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  if (!userRoles.value.includes("edit-product-family")) {
    router.push("/");
  }

  const handleCompanyContactCreation = () => {
    const companyContact: ICompanyContact = {
      name: contactName.value,
      company_id: Number(selectedCompany.value),
    };

    if (contactPhone.value) {
      companyContact.phone = contactPhone.value;
    }
    if (contactEmail.value) {
      companyContact.email = contactEmail.value;
    }
    store.dispatch(CREATE_COMPANY_CONTACT, companyContact);
  };
</script>
