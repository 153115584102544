import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-showinvoices__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "cst-spinner",
  "data-testid": "v-invoices-spinner"
}
const _hoisted_3 = {
  key: 1,
  class: "v-showinvoices__table-container",
  "data-testid": "vshowinvoices__table"
}
const _hoisted_4 = {
  key: 0,
  class: "v-showinvoices__table-wrapper"
}
const _hoisted_5 = {
  class: "v-showinvoices__table",
  "aria-describedby": "Table of invoices"
}
const _hoisted_6 = { class: "v-showinvoices__table-head-row" }
const _hoisted_7 = {
  key: 0,
  "data-testid": "vshowinvoices-documentcolumn"
}
const _hoisted_8 = { class: "v-showinvoices__table-row" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 3 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["colspan"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "v-showinvoices__li-table-row-wrapper" }
const _hoisted_19 = {
  key: 0,
  class: "v-showinvoices__li-table-row"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 2 }
const _hoisted_22 = {
  key: 0,
  class: "v-showinvoices__text-center v-showinvoices__title",
  "data-testid": "vshowinvoices-noinvoices"
}

import { formatNumber } from "@/utils/helpers/formatNumber";
  import { computed, ref, Ref, ComputedRef, watch } from "vue";
  import { useStore } from "vuex";
  import { IInvoice, IInvoiceShowLI } from "@/utils/interfaces/IInvoice";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import ExportDocumentModal from "@/components/modals/C_ExportDocumentModal.vue";
  import C_Search from "@/components/C_Search.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_ShowInvoices',
  setup(__props) {

  const { FETCH_INVOICES, SHOW_EXPORT_DOCUMENT_MODAL, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
  const store = useStore();
  const isModalOpen = computed(() => store.getters.getShowExportDocumentModal);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const invoiceID = ref();
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const invoices: ComputedRef<IInvoice[]> = computed(() => store.getters.getInvoices);
  const filteredInvoices = ref(invoices.value?.slice(0, 10));
  const paginatedList = ref(invoices.value?.slice(0, 10));

  const areInvoicesFetched = computed(() => store.getters.getAreInvoicesFetched);

  const isInvoiceListEmpty = computed(() => {
    if (invoices.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const totalPages = computed(() => {
    if (invoices.value.length > 10 && invoices.value.length % 10 != 0) {
      return Math.floor(invoices.value.length / 10) + 1;
    } else {
      return invoices.value.length / 10;
    }
  });

  const expandedItem = ref();
  const expandedIndex = ref<number | null>(null);

  const invoiceExportEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.invoices_export_enabled;
    } else {
      return false;
    }
  });

  const hasExportPermission = computed(() => {
    if (userRoles.value.includes("edit-invoice")) {
      return true;
    } else {
      return false;
    }
  });

  const dateRangeEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.invoice_has_date_range;
    } else {
      return false;
    }
  });

  const tableHeaders: Ref<string[]> = dateRangeEnabled.value ? ref(["Start Date", "End Date", "Title", "Company Contact", "PO Name", "Amount", "Details"]) : ref(["Date", "Title", "Company Contact", "PO Name", "Amount", "Details"]);

  const fetchInvoices = () => {
    store.dispatch(FETCH_INVOICES);
  };

  fetchInvoices();

  const toggleDetails = (item: IInvoice, index: number) => {
    if (expandedIndex.value === index) {
      expandedIndex.value = null;
    } else {
      expandedIndex.value = index;
      expandedItem.value = item;
    }
  };

  const exportInvoice = (invoice: IInvoice) => {
    store.dispatch(SHOW_EXPORT_DOCUMENT_MODAL, true);
    invoiceID.value = invoice.id;
  };

  const filterInvoices = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;
    filteredInvoices.value = invoices.value.filter((invoice: IInvoice) => {
      if (invoice.po_name) return invoice.po_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });

    if (searchTerm === "") {
      filteredInvoices.value = paginatedList.value;
    }
  };

  watch([invoices], () => {
    paginatedList.value = invoices.value?.slice(0, 10);
    filteredInvoices.value = invoices.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IInvoice>) => {
    filteredInvoices.value = filteredList;
    paginatedList.value = filteredList;
    expandedIndex.value = null;
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "v-showinvoices__text-center v-showinvoices__title" }, "List of Invoices", -1)),
      (!areInvoicesFetched.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (invoices.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(C_Search, {
                placeholder: 'Search by PO Name...',
                onInput: _cache[0] || (_cache[0] = ($event: any) => (filterInvoices($event)))
              }),
              (filteredInvoices.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("table", _hoisted_5, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableHeaders), (item, index) => {
                            return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(item), 1))
                          }), 128)),
                          (invoiceExportEnabled.value && hasExportPermission.value)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_7, "Document"))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredInvoices.value, (item, index) => {
                        return (_openBlock(), _createElementBlock("tbody", { key: index }, [
                          _createElementVNode("tr", _hoisted_8, [
                            (!dateRangeEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_9, _toDisplayString(_unref(formatDate)(item.date)), 1))
                              : _createCommentVNode("", true),
                            (dateRangeEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_10, _toDisplayString(item.start_date ? _unref(formatDate)(item.start_date) : "-"), 1))
                              : _createCommentVNode("", true),
                            (dateRangeEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_11, _toDisplayString(item.end_date ? _unref(formatDate)(item.end_date) : "-"), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", null, _toDisplayString(item.name), 1),
                            _createElementVNode("td", null, _toDisplayString(item.contact_name), 1),
                            _createElementVNode("td", null, _toDisplayString(item.po_name), 1),
                            _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)((Number(item.total_amount) * currencyRate.value).toFixed(2)) + " " + selectedCurrency.value), 1),
                            _createElementVNode("td", null, [
                              _createElementVNode("button", {
                                title: "Show",
                                class: "c-requestbtns c-requestbtns-secondary-show",
                                "data-testid": "vshowinvoices-detailsbtn",
                                onClick: ($event: any) => (toggleDetails(item, index))
                              }, [
                                _createElementVNode("p", null, _toDisplayString(expandedIndex.value === index ? "Hide" : "Show"), 1),
                                _cache[1] || (_cache[1] = _createElementVNode("svg", {
                                  fill: "#000000",
                                  width: "15px",
                                  height: "15px",
                                  viewBox: "-1 0 19 19",
                                  class: "cf-icon-svg"
                                }, [
                                  _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                ], -1))
                              ], 8, _hoisted_12)
                            ]),
                            (hasExportPermission.value && invoiceExportEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                                  _createElementVNode("button", {
                                    title: "Export",
                                    class: "c-requestbtns c-requestbtns-secondary-show",
                                    "data-testid": "vshowinvoices-exportbtn",
                                    onClick: ($event: any) => (exportInvoice(item))
                                  }, _cache[2] || (_cache[2] = [
                                    _createTextVNode(" Export "),
                                    _createElementVNode("svg", {
                                      fill: "#000000",
                                      viewBox: "0 0 50 32",
                                      version: "1.1",
                                      preserveAspectRatio: "xMidYMid meet"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M6,13.61h7.61V6H24v8.38h2V6a2,2,0,0,0-2-2H10.87L4,10.87V30a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2H6Zm0-1.92L11.69,6H12v6H6Z",
                                        class: "clr-i-outline clr-i-outline-path-1"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M28.32,16.35a1,1,0,0,0-1.41,1.41L30.16,21H18a1,1,0,0,0,0,2H30.19l-3.28,3.28a1,1,0,1,0,1.41,1.41L34,22Z",
                                        class: "clr-i-outline clr-i-outline-path-2"
                                      }),
                                      _createElementVNode("rect", {
                                        x: "0",
                                        y: "0",
                                        width: "32",
                                        height: "32",
                                        "fill-opacity": "0"
                                      })
                                    ], -1)
                                  ]), 8, _hoisted_14)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          (expandedIndex.value !== null && expandedIndex.value === index)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [
                                _createElementVNode("td", {
                                  colspan: hasExportPermission.value ? 8 : 6
                                }, [
                                  (expandedItem.value.listitems && expandedItem.value.listitems.length > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                        _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "v-showinvoices__listitems" }, "List Items:", -1)),
                                        _cache[4] || (_cache[4] = _createElementVNode("div", {
                                          class: "v-showinvoices__li-table-header",
                                          "data-testid": "vshowinvoices-listitems"
                                        }, [
                                          _createElementVNode("p", null, "No."),
                                          _createElementVNode("p", null, "Name"),
                                          _createElementVNode("p", null, "Resource"),
                                          _createElementVNode("p", null, "Person Days"),
                                          _createElementVNode("p", null, "Cost type"),
                                          _createElementVNode("p", null, "Daily rate"),
                                          _createElementVNode("p", null, "Amount")
                                        ], -1)),
                                        _createElementVNode("div", _hoisted_18, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(expandedItem.value.listitems, (item, index) => {
                                            return (_openBlock(), _createElementBlock("div", { key: index }, [
                                              (item && typeof item === 'object')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                                    _createElementVNode("p", null, _toDisplayString(index + 1), 1),
                                                    _createElementVNode("p", null, _toDisplayString((item as IInvoiceShowLI).list_item_name), 1),
                                                    _createElementVNode("p", null, _toDisplayString((item as IInvoiceShowLI).resource_name), 1),
                                                    _createElementVNode("p", null, _toDisplayString((item as IInvoiceShowLI).quantity), 1),
                                                    _createElementVNode("p", null, _toDisplayString((item as IInvoiceShowLI).cost_type), 1),
                                                    _createElementVNode("p", null, _toDisplayString((item as IInvoiceShowLI).daily_rate), 1),
                                                    _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)(Number((item as IInvoiceShowLI).amount).toFixed(2)) + " " + (item as IInvoiceShowLI).currency), 1)
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ]))
                                          }), 128))
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 8, _hoisted_16)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[5] || (_cache[5] = [
                    _createElementVNode("p", { class: "v-showinvoices--empty" }, "No such invoices found", -1)
                  ]))),
              (totalPages.value > 1)
                ? (_openBlock(), _createBlock(C_Pagination, {
                    key: 2,
                    totalPages: totalPages.value,
                    itemList: invoices.value,
                    onPaginateList: paginateList,
                    "data-testid": "pages-indicator"
                  }, null, 8, ["totalPages", "itemList"]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_21, [
              (isInvoiceListEmpty.value)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_22, "No invoices found"))
                : _createCommentVNode("", true)
            ])),
      (isModalOpen.value)
        ? (_openBlock(), _createBlock(ExportDocumentModal, {
            key: 3,
            invoiceID: invoiceID.value
          }, null, 8, ["invoiceID"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})