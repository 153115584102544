import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "v-filter-companies__wrapper",
  "data-testid": "companies-wrapper"
}
const _hoisted_2 = { class: "v-filter-companies__filter-wrapper" }
const _hoisted_3 = { class: "v-filter-companies__products-wrapper" }
const _hoisted_4 = { class: "v-filter-products__summary-overview" }
const _hoisted_5 = {
  class: "v-filter-products__summary-overview--title",
  "data-testid": "summary-overview"
}
const _hoisted_6 = { class: "v-filter-companies__toggle-view" }
const _hoisted_7 = { class: "v-filter-companies__toggle-view--sort" }
const _hoisted_8 = {
  key: 0,
  value: "city-descending"
}
const _hoisted_9 = {
  key: 1,
  value: "city-ascending"
}
const _hoisted_10 = {
  key: 2,
  value: "country-descending"
}
const _hoisted_11 = {
  key: 3,
  value: "country-ascending"
}
const _hoisted_12 = {
  key: 0,
  "data-testid": "companies-container"
}
const _hoisted_13 = {
  key: 0,
  class: "c-table-overview__header"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "c-table-overview__body-row" }
const _hoisted_16 = { class: "c-table-overview__body-row--expand-icon" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { "data-testid": "companies-name" }
const _hoisted_21 = {
  key: 0,
  class: "c-table-overview__body-row--expanded-container",
  "data-testid": "company-contacts-overview"
}
const _hoisted_22 = { class: "c-table-overview__body-row" }
const _hoisted_23 = { "data-testid": "contacts-name" }
const _hoisted_24 = {
  key: 1,
  class: "c-table-overview__body-row--expanded-container"
}
const _hoisted_25 = {
  key: 2,
  class: "cst-spinner"
}
const _hoisted_26 = {
  key: 2,
  class: "c-table-overview__body-row--empty"
}
const _hoisted_27 = {
  key: 3,
  class: "c-table-overview__body-row--empty"
}
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  key: 0,
  class: "c-table-overview__header"
}
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { class: "c-table-overview__body-row" }
const _hoisted_32 = { "data-testid": "company-contacts-name" }
const _hoisted_33 = {
  key: 2,
  class: "c-table-overview__body-row--empty"
}
const _hoisted_34 = {
  key: 3,
  class: "c-table-overview__body-row--empty"
}
const _hoisted_35 = {
  key: 2,
  class: "cst-spinner",
  "data-testid": "v-companies-spinner"
}

import { VUEX_ACTIONS } from "@/utils/constants";
  import { ref, computed, watch } from "vue";
  import { useStore } from "vuex";
  import FilterBar from "@/components/C_FilterBar.vue";
  import C_Search from "@/components/C_Search.vue";
  import { ISelectedFilter } from "@/utils/interfaces/ISelectedFilter";
  import { ICompany } from "@/utils/interfaces/ICompany";
  import { ICompanyContact } from "@/utils/interfaces/ICompanyContact";

  const header = "Filter";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_Companies',
  setup(__props) {

  const { FETCH_ALL_COMPANIES, FETCH_COMPANY_FILTERS, FETCH_SPECIFIC_COMPANY } = VUEX_ACTIONS;
  const store = useStore();
  store.dispatch(FETCH_ALL_COMPANIES);
  store.dispatch(FETCH_COMPANY_FILTERS);
  store.commit("setAreCompaniesFetched", false);
  store.commit("setCompanyContacts", []);
  store.commit("setContactsFetched", false);
  store.commit("setIsCompanyRowExpanded", false);
  const selectedCompany = ref("");
  const sortingOption = ref("default");
  const expandedCompanyRows = ref<number[]>([]);
  const filters = computed(() => store.getters.getCompanyFilters);
  const allCompanies = computed(() => store.getters.getAllCompanies);
  const areCompaniesFetched = computed(() => store.getters.getAreCompaniesFetched);
  const companyContacts = computed(() => store.getters.getCompanyContacts);
  const areContactsFetched = computed(() => store.getters.getContactsFetched);
  const areFiltersFetched = computed(() => store.getters.getFiltersFetched);
  const isCompanyRowExpanded = computed(() => store.getters.getIsCompanyRowExpanded);

  let filteredCompanies = ref<ICompany[]>(allCompanies.value);
  const sortedCompanies = computed(() => {
    switch (sortingOption.value) {
      case "default":
        return [...filteredCompanies.value].sort((a, b) => a.name.localeCompare(b.name));
      case "name-ascending":
        return [...filteredCompanies.value].sort((a, b) => b.name.localeCompare(a.name));
      case "city-descending":
        return [...filteredCompanies.value].sort((a, b) => a.city.localeCompare(b.city));
      case "city-ascending":
        return [...filteredCompanies.value].sort((a, b) => b.city.localeCompare(a.city));
      case "country-descending":
        return [...filteredCompanies.value].sort((a, b) => a.country.localeCompare(b.country));
      case "country-ascending":
        return [...filteredCompanies.value].sort((a, b) => b.country.localeCompare(a.country));
      default:
        return filteredCompanies.value;
    }
  });

  let filteredContacts = ref<ICompanyContact[]>(companyContacts.value);
  const sortedContacts = computed(() => {
    switch (sortingOption.value) {
      case "default":
        return [...filteredContacts.value].sort((a, b) => a.name.localeCompare(b.name));
      case "name-ascending":
        return [...filteredContacts.value].sort((a, b) => b.name.localeCompare(a.name));
      default:
        return filteredContacts.value;
    }
  });

  const handleFilterSubmit = async (selectedFilter: ISelectedFilter[]) => {
    sortingOption.value = "default";
    selectedCompany.value = "";
    expandedCompanyRows.value = [];

    store.commit("setCompanyContacts", []);
    store.commit("setContactsFetched", false);
    store.commit("setIsCompanyRowExpanded", false);

    if (selectedFilter.length > 0) {
      for (const id in selectedFilter) {
        let name = selectedFilter[id].name;

        if (selectedFilter[id].label === "Companies") {
          if (name === "All") {
            store.dispatch(FETCH_ALL_COMPANIES);
          } else {
            store.dispatch(FETCH_SPECIFIC_COMPANY, selectedFilter[id].option);
            selectedCompany.value = name;
          }
        }
      }
    }
  };

  const handleOverviewClick = async (selectedEntity: ICompany) => {
    store.commit("setIsCompanyRowExpanded", false);
    expandedCompanyRows.value = [];
    selectedCompany.value = selectedEntity["name"];
    sortingOption.value = "default";
    store.dispatch(FETCH_SPECIFIC_COMPANY, selectedEntity["id"]);
  };

  const handleExpandRows = async (index: number, selectedEntity: ICompany) => {
    if (expandedCompanyRows.value.includes(index)) {
      store.commit("setCompanyContacts", []);
      store.commit("setContactsFetched", false);
      store.commit("setIsCompanyRowExpanded", false);
      expandedCompanyRows.value = [];
    } else {
      store.commit("setIsCompanyRowExpanded", true);
      expandedCompanyRows.value = [index];
      store.dispatch(FETCH_SPECIFIC_COMPANY, selectedEntity["id"]);
    }
  };

  const filterCompaniesContacts = async (event: Event) => {
    store.commit("setIsCompanyRowExpanded", false);
    expandedCompanyRows.value = [];

    const searchTerm = (event.target as HTMLInputElement).value;
    const attributesToCheckInContact = ["name", "phone", "email"];
    const attributesToCheckInCompany = ["name", "street", "house_number", "city", "postal_code", "country"];

    if (searchTerm == "" || !searchTerm) {
      filteredCompanies.value = allCompanies.value;
      filteredContacts.value = companyContacts.value;
    } else {
      if (selectedCompany.value) {
        filteredContacts.value = companyContacts.value.filter((contact: any) => {
          return attributesToCheckInContact.some((attribute) => contact[attribute] && typeof contact[attribute] === "string" && contact[attribute].toLowerCase().includes(searchTerm.toLowerCase()));
        });
      }
      if (!selectedCompany.value) {
        store.commit("setContactsFetched", false);
        filteredCompanies.value = allCompanies.value.filter((company: any) => {
          const companyMatches = attributesToCheckInCompany.some((attribute) => company[attribute] && typeof company[attribute] === "string" && company[attribute].toLowerCase().includes(searchTerm.toLowerCase()));
          const contactMatches =
            company.contacts &&
            company.contacts.some((contact: any) => {
              return attributesToCheckInContact.some((attribute) => contact[attribute] && typeof contact[attribute] === "string" && contact[attribute].toLowerCase().includes(searchTerm.toLowerCase()));
            });
          return companyMatches || contactMatches;
        });
      }
    }
  };

  watch(companyContacts, () => {
    filteredContacts.value = companyContacts.value;
  });
  watch(allCompanies, () => {
    filteredCompanies.value = allCompanies.value;
  });
  watch(sortingOption, () => {
    if (!selectedCompany.value) {
      store.commit("setIsCompanyRowExpanded", false);
      expandedCompanyRows.value = [];
      store.commit("setContactsFetched", false);
    }
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (areFiltersFetched.value)
        ? (_openBlock(), _createBlock(FilterBar, {
            key: 0,
            header: header,
            filterList: filters.value,
            isCompany: true,
            onSubmit: handleFilterSubmit
          }, null, 8, ["filterList"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(selectedCompany.value ? selectedCompany.value : "All") + " " + _toDisplayString(selectedCompany.value ? "Company Contacts" : "Companies"), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, [
            _cache[4] || (_cache[4] = _createTextVNode("Sort by: ")),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((sortingOption).value = $event)),
              "data-testid": "sortOptions"
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("option", {
                value: "default",
                selected: ""
              }, "Name ↓", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("option", { value: "name-ascending" }, "Name ↑", -1)),
              (!selectedCompany.value)
                ? (_openBlock(), _createElementBlock("option", _hoisted_8, "City ↓"))
                : _createCommentVNode("", true),
              (!selectedCompany.value)
                ? (_openBlock(), _createElementBlock("option", _hoisted_9, "City ↑"))
                : _createCommentVNode("", true),
              (!selectedCompany.value)
                ? (_openBlock(), _createElementBlock("option", _hoisted_10, "Country ↓"))
                : _createCommentVNode("", true),
              (!selectedCompany.value)
                ? (_openBlock(), _createElementBlock("option", _hoisted_11, "Country ↑"))
                : _createCommentVNode("", true)
            ], 512), [
              [_vModelSelect, sortingOption.value]
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createVNode(C_Search, {
            placeholder: 'Search by...',
            isCompany: true,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (filterCompaniesContacts($event))),
            "data-testid": "companies-search"
          })
        ])
      ]),
      (areCompaniesFetched.value && ((!areContactsFetched.value && !selectedCompany.value) || (areContactsFetched.value && isCompanyRowExpanded.value)))
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (sortedCompanies.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[5] || (_cache[5] = [
                  _createElementVNode("div", null, "Overview", -1),
                  _createElementVNode("div", null, null, -1),
                  _createElementVNode("div", null, "Name", -1),
                  _createElementVNode("div", null, "Street", -1),
                  _createElementVNode("div", null, "House Number", -1),
                  _createElementVNode("div", null, "City", -1),
                  _createElementVNode("div", null, "Postal Code", -1),
                  _createElementVNode("div", null, "Country", -1)
                ])))
              : _createCommentVNode("", true),
            (sortedCompanies.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedCompanies.value, (entity, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      "data-testid": "companies"
                    }, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("img", {
                            src: './arrow-down.svg',
                            alt: "expand down icon",
                            onClick: ($event: any) => (handleExpandRows(index, entity)),
                            "data-testid": "companies-overview-button"
                          }, null, 8, _hoisted_17)
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("button", {
                            onClick: ($event: any) => (handleOverviewClick(entity)),
                            "data-testid": "companies-details-button"
                          }, "Details", 8, _hoisted_19)
                        ]),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(entity.name), 1),
                        _createElementVNode("div", null, _toDisplayString(entity.street), 1),
                        _createElementVNode("div", null, _toDisplayString(entity.house_number), 1),
                        _createElementVNode("div", null, _toDisplayString(entity.city), 1),
                        _createElementVNode("div", null, _toDisplayString(entity.postal_code), 1),
                        _createElementVNode("div", null, _toDisplayString(entity.country), 1)
                      ]),
                      (expandedCompanyRows.value.includes(index) && companyContacts.value.length > 0 && areContactsFetched.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createElementVNode("p", null, [
                              _cache[6] || (_cache[6] = _createTextVNode(" Contacts belonging to ")),
                              _createElementVNode("strong", null, _toDisplayString(entity.name), 1)
                            ]),
                            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-table-overview__header c-table-overview__header--expanded" }, [
                              _createElementVNode("div", null, "Name"),
                              _createElementVNode("div", null, "Phone Number"),
                              _createElementVNode("div", null, "Email Address")
                            ], -1)),
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companyContacts.value, (entity, index) => {
                                return (_openBlock(), _createElementBlock("div", { key: index }, [
                                  _createElementVNode("div", _hoisted_22, [
                                    _createElementVNode("div", _hoisted_23, _toDisplayString(entity.name), 1),
                                    _createElementVNode("div", null, _toDisplayString(entity.phone), 1),
                                    _createElementVNode("div", null, _toDisplayString(entity.email), 1)
                                  ])
                                ]))
                              }), 128))
                            ])
                          ]))
                        : (expandedCompanyRows.value.includes(index) && companyContacts.value.length === 0 && areContactsFetched.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[8] || (_cache[8] = [
                              _createElementVNode("p", null, "There are no contacts in this company.", -1)
                            ])))
                          : (expandedCompanyRows.value.includes(index) && !areContactsFetched.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_25))
                            : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : (sortedCompanies.value.length === 0 && allCompanies.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, "No companies match your search criteria!"))
                : (allCompanies.value.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, "There are no companies to show!"))
                  : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (areContactsFetched.value && selectedCompany.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            (sortedContacts.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, _cache[9] || (_cache[9] = [
                  _createElementVNode("div", null, "Name", -1),
                  _createElementVNode("div", null, "Phone Number", -1),
                  _createElementVNode("div", null, "Email Address", -1)
                ])))
              : _createCommentVNode("", true),
            (sortedContacts.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedContacts.value, (entity, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, _toDisplayString(entity.name), 1),
                        _createElementVNode("div", null, _toDisplayString(entity.phone), 1),
                        _createElementVNode("div", null, _toDisplayString(entity.email), 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : (sortedContacts.value.length === 0 && companyContacts.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, "No contacts match your search criteria!"))
                : (_openBlock(), _createElementBlock("div", _hoisted_34, "There are no contacts in this company."))
          ]))
        : ((!areContactsFetched.value && selectedCompany.value) || !areCompaniesFetched.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_35))
          : _createCommentVNode("", true)
    ])
  ]))
}
}

})