<template>
  <div class="c-submenu__nav">
    <div class="c-submenu__nav-wrapper">
      <nav>
        <ul class="c-submenu__nav-links" data-testid="submenu-wrapper">
          <li class="c-submenu__nav-link" @mouseover="showSubmenu = true" @mouseleave="showSubmenu = false" data-testid="submenu-item-overviews">
            <p>Overviews</p>
            <div
              :class="{
                active: isRouteActive('/') || isRouteActive('/cost-units'),
              }"
            ></div>
            <div v-if="showSubmenu" class="c-submenu__nav-subnav" data-testid="submenu-overviews-items">
              <div data-testid="submenu-item-productoverview" @click="handleProductClick">Product Overview</div>
              <div v-if="canCreateCU && showCostUnits" @click="handleCUOverviewClick" data-testid="submenu-item-costunits">Cost Units Overview</div>
            </div>
          </li>
          <li class="c-submenu__nav-link" @mouseover="showCreateSubmenu = true" @mouseleave="showCreateSubmenu = false" data-testid="submenu-item-createnew" v-if="canCreatePF || canCreateProd || canCreateProjOrApp">
            <p>Create New</p>
            <div
              :class="{
                active: isRouteActive('/create-prodfamily') || isRouteActive('/create-product') || isRouteActive('/create-project') || isRouteActive('/create-app') || isRouteActive('/create-cost-unit'),
              }"
            ></div>
            <div v-if="showCreateSubmenu" class="c-submenu__nav-subnav" data-testid="submenu-createnew-items">
              <div v-if="canCreatePF" @click="handleProductFamilyClick" data-testid="submenu-item-createproductfamily">Product Family</div>
              <div @click="handleCreateProductClick" v-if="canCreateProd" data-testid="submenu-item-createproduct">Product</div>
              <div @click="handleProjectClick" v-if="canCreateProjOrApp" data-testid="submenu-item-createproject">Project</div>
              <div @click="handleAppClick" v-if="canCreateProjOrApp" data-testid="submenu-item-createapp">Application</div>
              <div @click="handleCompanyClick" v-if="canCreateCompanyOrContact" data-testid="submenu-item-createcompany">Company</div>
              <div @click="handleContactClick" v-if="canCreateCompanyOrContact" data-testid="submenu-item-createcontact">Contact</div>

              <div v-if="canCreateCU && showCostUnits" @click="handleCuCreateClick" data-testid="submenu-item-createcu">Cost Unit</div>
            </div>
          </li>
          <!--***TEMPORARILY DISABLED ROUTE***-->
          <!--<li class="c-submenu__nav-link">
          <router-link to="/data-entry" @click="handleUpdateClick"
            >Product update</router-link
          >
          <div :class="{ active: isRouteActive('/data-entry') }"></div>
        </li>-->
          <li class="c-submenu__nav-link" @mouseover="showPRSubmenu = true" @mouseleave="showPRSubmenu = false" data-testid="submenu-item-requests">
            <p>Requests</p>
            <div
              :class="{
                active: isRouteActive('/requests') || isRouteActive('/create-request') || isRouteActive('/archive'),
              }"
            ></div>
            <div v-if="showPRSubmenu" class="c-submenu__nav-subnav" data-testid="submenu-request-items">
              <div to="/requests" @click="handleOpenPRClick" data-testid="submenu-item-openrequests">Open Requests</div>
              <div @click="handleCreatePRClick" v-if="canCreatePR" data-testid="submenu-item-createpr">Create Request</div>
              <div v-if="showArchive" @click="handleArchiveClick" data-testid="submenu-item-archive">Archive</div>
            </div>
          </li>
          <li class="c-submenu__nav-link" @mouseover="showPOSubmenu = true" @mouseleave="showPOSubmenu = false" data-testid="submenu-item-orders">
            <p>Orders</p>
            <div
              :class="{
                active: isRouteActive('/orders-expiration') || isRouteActive('/all-orders'),
              }"
            ></div>
            <div v-if="showPOSubmenu" class="c-submenu__nav-subnav" data-testid="submenu-orders-items">
              <div @click="handleExpirationClick" data-testid="submenu-item-expirationlist">Expiration list</div>
              <div @click="handleAllOrders" data-testid="submenu-item-allorders">All orders</div>
            </div>
          </li>

          <li class="c-submenu__nav-link" data-testid="submenu-item-invoices" @mouseover="showInvoiceSubmenu = true" @mouseleave="showInvoiceSubmenu = false">
            <router-link v-if="!showCSVUpload" to="/invoices"><p>Invoices</p></router-link>
            <p v-else>Invoices</p>
            <div v-if="showInvoiceSubmenu" :class="{ active: isRouteActive('/invoices') }"></div>

            <div v-if="showInvoiceSubmenu && showCSVUpload" class="c-submenu__nav-subnav" data-testid="submenu-orders-items">
              <router-link to="/invoices"><div>Invoice list</div></router-link>
              <router-link v-if="showCSVUpload" to="/import-csv"><div data-testid="submenu-item-csv">Upload CSV</div></router-link>
            </div>
          </li>

          <li class="c-submenu__nav-link" data-testid="submenu-item-contacts">
            <router-link to="/companies"><p>Companies</p></router-link>
          </li>

          <li class="c-submenu__nav-link" @mouseover="showFinancialsSubmenu = true" @mouseleave="showFinancialsSubmenu = false">
            <p>{{ financialGoalLabel }} Planning</p>
            <div v-if="showFinancialsSubmenu" class="c-submenu__nav-subnav" data-testid="submenu-createnew-items">
              <div @click="handleFutureFinancialsOverviewClick" data-testid="submenu-item-future-financials-overview">Overview</div>
              <div @click="handleFutureFinancialsProposalClick" data-testid="submenu-item-create-future-financials-proposal">Proposal</div>
              <div @click="handleFutureFinancialsDemandClick" data-testid="submenu-item-create-future-financials-demand">Demand</div>
            </div>
          </li>
        </ul>
      </nav>
      <div class="c-submenu__nav--right">
        <select title="currency" class="cst-select cst-select--small" id="currency-select" @change="updateCurrency" v-model="selectedCurrency" data-testid="submenu-currency-dropdown">
          <option v-for="currency in currencies" :key="currency.id" :value="currency.id" data-testid="submenu-list-currencies">
            {{ currency.id }}
          </option>
        </select>
        <UserDropdown />
        <Notification />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from "vue";
  import UserDropdown from "./C_UserDropdown.vue";
  import Notification from "./C_Notification.vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "Submenu",
    components: {
      UserDropdown,
      Notification,
    },
    methods: {
      isRouteActive(route: string): boolean {
        return this.$route.path === route;
      },
    },
    setup() {
      const { UPDATE_SELECTED_CURRENCY } = VUEX_ACTIONS;
      const store = useStore();
      const showSubmenu = ref(false);
      const showCreateSubmenu = ref(false);
      const showPRSubmenu = ref(false);
      const showPOSubmenu = ref(false);
      const showFinancialsSubmenu = ref(false);

      const router = useRouter();
      const showInvoiceSubmenu = ref(false);
      const user = computed(() => store.getters.getCurrentUser);
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const currencies = computed(() => store.getters.getAllCurrencies);
      const selectedCurrency = ref("EUR");
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const showCSVUpload = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.invoice_importer_enabled;
        } else {
          return false;
        }
      });

      const financialGoalLabel = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.financial_goal_label;
        } else {
          return "Budget";
        }
      });

      const showCostUnits = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.cost_units_enabled;
        } else {
          return true;
        }
      });

      const showArchive = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.purchase_request_archive_enabled;
        } else {
          return true;
        }
      });

      const canCreatePF = computed(() => {
        return userRoles.value?.includes("edit-product-family") ?? false;
      });

      const canCreateCompanyOrContact = computed(() => {
        return userRoles.value?.includes("edit-purchase-order") ?? false;
      });

      const canCreateCU = computed(() => {
        return userRoles.value?.includes("edit-cost-unit") ?? false;
      });

      const canCreatePR = computed(() => {
        return userRoles.value?.includes("edit-purchase-request") ?? false;
      });

      const canCreateProd = computed(() => {
        return userRoles.value?.includes("edit-product-hierarchy") ?? false;
      });

      const canCreateProjOrApp = computed(() => {
        return userRoles.value?.includes("edit-project-application") ?? false;
      });

      const updateCurrency = (event: Event) => {
        const currency = (event.target as HTMLSelectElement).value;
        store.dispatch(UPDATE_SELECTED_CURRENCY, currency);
        selectedCurrency.value = currency;
      };

      const handleCUOverviewClick = () => {
        router.push("/cost-units");
        showSubmenu.value = false;
      };

      const handleCuCreateClick = () => {
        router.push("/create-cost-unit");
      };

      const handleProductFamilyClick = () => {
        router.push("/create-prodfamily");
        showSubmenu.value = false;
      };

      const handleProductClick = () => {
        router.push("/");
        showSubmenu.value = false;
      };

      const handleCreateProductClick = () => {
        router.push("/create-product");
        showSubmenu.value = false;
      };

      const handleProjectClick = () => {
        store.commit("setCreatedProduct", []);
        store.commit("setProductRemainingBudget", null);
        router.push("/create-project");
        showSubmenu.value = false;
      };

      const handleCompanyClick = () => {
        router.push("/create-company");
        showSubmenu.value = false;
      };

      const handleContactClick = () => {
        router.push("/create-contact");
        showSubmenu.value = false;
      };

      const handleAppClick = () => {
        store.commit("setCreatedProduct", []);
        store.commit("setProductRemainingBudget", null);
        router.push("/create-app");
        showSubmenu.value = false;
      };

      const handleArchiveClick = () => {
        router.push("/archive");
        showPRSubmenu.value = false;
      };

      const handleOpenPRClick = () => {
        router.push("/requests");
        showPRSubmenu.value = false;
      };

      const handleCreatePRClick = () => {
        showPRSubmenu.value = false;
        router.push("/create-request");
      };

      const handleExpirationClick = () => {
        showPRSubmenu.value = false;
        router.push("/orders-expiration");
      };

      const handleAllOrders = () => {
        showPRSubmenu.value = false;
        router.push("/all-orders");
      };

      const handleFutureFinancialsOverviewClick = () => {
        showFinancialsSubmenu.value = false;
        router.push("/financial-plan-overview");
      };

      const handleFutureFinancialsProposalClick = () => {
        showFinancialsSubmenu.value = false;
        router.push("/proposal");
      };

      const handleFutureFinancialsDemandClick = () => {
        showFinancialsSubmenu.value = false;
        router.push("/demand");
      };

      return {
        currencies,
        updateCurrency,
        handleCUOverviewClick,
        handleProductFamilyClick,
        handleProductClick,
        handleCreateProductClick,
        handleProjectClick,
        handleAppClick,
        handleArchiveClick,
        handleOpenPRClick,
        handleCreatePRClick,
        handleCuCreateClick,
        handleExpirationClick,
        handleAllOrders,
        handleFutureFinancialsOverviewClick,
        handleFutureFinancialsProposalClick,
        handleFutureFinancialsDemandClick,
        handleCompanyClick,
        handleContactClick,
        showSubmenu,
        showCreateSubmenu,
        showPRSubmenu,
        showPOSubmenu,
        canCreatePF,
        user,
        showCSVUpload,
        financialGoalLabel,
        showCostUnits,
        showArchive,
        selectedCurrency,
        canCreateCU,
        showInvoiceSubmenu,
        canCreatePR,
        canCreateCompanyOrContact,
        canCreateProd,
        canCreateProjOrApp,
        showFinancialsSubmenu,
      };
    },
  });
</script>
