<template>
  <RequestMessage></RequestMessage>
  <div class="v-filter-products__wrapper" data-testid="filterproducts-wrapper">
    <div class="v-filter-products__filter-wrapper">
      <FilterBar :header="header" :filterList="filters" :isProduct="true" @submit="handleFilterSubmit" v-if="filtersLoaded" />
      <div class="v-filter-products__toggle-view--return" v-if="canSeePFOverview && (productInOverviewName || selectedProductFamily)" @click="handleReturnToPFOverview">← Product Family Overview</div>
    </div>
    <div class="v-filter-products__products-wrapper">
      <div class="v-filter-products__summary-overview">
        <div class="v-filter-products__summary-overview--title" data-testid="summary-overview">
          <button v-if="productInOverviewName && !isPlatform && costUnitsEnabled" class="v-filter-products__summary-overview--btn--cu-allocation cst-button cst-button-primary" type="button" @click="handleCUAllocationPercentageClick">
            CU Allocation Percentage
          </button>
          <div data-testid="summary-overview-title">
            {{ productInOverviewName ? productInOverviewName : selectedProductFamily }}
            {{ productInOverviewName ? "Product Overview" : "Product Family Overview" }}
            <button v-if="isPlatform" class="v-filter-products__summary-overview--btn--consumers cst-button cst-button-primary" type="button" @click="handleConsumersClick">Consumers</button>
            <button v-if="isPlatform && canManagePlatform" class="v-filter-products__summary-overview--btn--manage cst-button cst-button-primary" type="button" @click="handleManageClick">Manage Platform</button>
          </div>
        </div>
        <div v-if="areProductsFetched">
          <BudgetSummary
            size="big"
            :key="productFamily.id"
            :budget="projAppFetched && isProductFocused ? projAppTarget : totals.totalBUDG"
            :forecast="projAppFetched && isProductFocused ? projAppForecast : totals.totalFCST"
            :actuals="projAppFetched && isProductFocused ? projAppActuals : totals.totalACT"
            data-testid="budget-summary"
          />
        </div>
        <div v-if="!areProductsFetched" class="c-budgetsummary__wrapper--empty" data-testid="budget-summary-empty">Select a filter on the left side to see the full overview.</div>
      </div>

      <div class="v-filter-products__toggle-view" v-if="areProductsFetched || isProductFocused || canSeePFOverview">
        <div class="v-filter-products__toggle-view--sort">
          <label
            >Sort by:
            <select v-model="sortingOption" data-testid="sortOptions">
              <option value="default" selected>A-Z</option>
              <option value="z-a">Z-A</option>
              <option value="budget-descending">Budget ↓</option>
              <option value="budget-ascending">Budget ↑</option>
              <option value="actuals-descending">Actuals ↓</option>
              <option value="actuals-ascending">Actuals ↑</option>
            </select>
          </label>
        </div>
        <div class="v-filter-products__toggle-view--arrangement">
          <img :src="'./grid.svg'" alt="grid view" :style="isGridView ? { border: '1px solid #525252' } : ''" @click="isGridView = !isGridView" />
          <img :src="'./list.svg'" alt="list view" :style="!isGridView ? { border: '1px solid #525252' } : ''" @click="isGridView = !isGridView" />
        </div>
      </div>
      <div class="v-filter-products__product-family-wrapper" v-if="!productInOverviewName && !areProductsFetched && !isFilterSubmitted && isGridView && canSeePFOverview">
        <ProductFamilyCard v-for="productFamily in sortedProductFamilies" :key="productFamily.id" :productfamily="productFamily" @selected-pf="handleProductFamilyOverview"></ProductFamilyCard>
      </div>
      <div v-else-if="!productInOverviewName && !areProductsFetched && !isFilterSubmitted && !isGridView && canSeePFOverview">
        <ProductFamilyTableOverview :allProductFamilies="sortedProductFamilies" :isproductfamily="true" @selected-pf="handleProductFamilyOverview" />
      </div>
      <div v-if="productInOverviewName && projAppFetched">
        <div class="v-filter-products__projectapp-cards" v-if="projectsApplicationsList.length > 0 && isGridView">
          <ProjAppCard v-for="projapp in sortedProjectsApplicationsList" :key="projapp.id" :projapp="projapp" @projapp-details="showProjAppDetails"></ProjAppCard>
        </div>
        <div v-else>
          <TableOverview @show-product-details="showProductDetails" @show-projapp-details="showProjAppDetails" :focusedproductid="focusedProductId" :isproductfocused="true" />
        </div>
      </div>

      <div v-if="!productInOverviewName && areProductsFetched">
        <div class="v-filter-products__product-cards" v-if="isGridView && products.length > 0">
          <ProductCard v-for="product in sortedProducts" :key="product.id" :product="product" @show-product-details="showProductDetails" @selected-product-id-name="showProjAppListInProduct" />
        </div>

        <div v-else-if="isFilterSubmitted">
          <TableOverview :productlist="sortedProducts" @show-product-details="showProductDetails" @show-projapp-details="showProjAppDetails" />
        </div>
      </div>
      <div v-if="isFilterSubmitted && ((!productInOverviewName && !areProductsFetched) || (productInOverviewName && !projAppFetched))" class="cst-spinner"></div>

      <div class="v-filter-products__charts" v-if="!productInOverviewName && selectedProductFamily">
        <PFCurrentYearChart :products="products"></PFCurrentYearChart>
        <PFLastNextYearChart :products="products"></PFLastNextYearChart>
      </div>
    </div>
    <ProductDetails :selectedProductId="selectedProductId"></ProductDetails>
  </div>
  <ProjectAppModal></ProjectAppModal>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from "vue";
  import FilterBar from "../components/C_FilterBar.vue";
  import ProjectAppModal from "../components/modals/C_ProjectAppModal.vue";
  import ProductCard from "../components/cards/C_ProductCard.vue";
  import ProjAppCard from "../components/cards/C_ProjAppCard.vue";
  import ProductFamilyCard from "../components/cards/C_ProductFamilyCard.vue";
  import BudgetSummary from "../components/C_BudgetSummary.vue";
  import ProductDetails from "../components/modals/C_ProductDetails.vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import PFCurrentYearChart from "../components/charts/C_PFCurrentYearChart.vue";
  import PFLastNextYearChart from "../components/charts/C_PFLastNextYearChart.vue";
  import TableOverview from "../components/C_TableOverview.vue";
  import ProductFamilyTableOverview from "../components/C_ProductFamilyTableOverview.vue";
  import { useStore } from "vuex";
  import { ISelectedFilter } from "@/utils/interfaces/ISelectedFilter";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";
  import { calculateProductPOForecast, calculateProductInvoiceActuals } from "@/utils/helpers/calculateProductForecastActuals";
  import { IProductFamily } from "@/utils/interfaces/IProductTypes";

  export default defineComponent({
    components: {
      FilterBar,
      ProductCard,
      BudgetSummary,
      ProductDetails,
      PFCurrentYearChart,
      PFLastNextYearChart,
      ProjAppCard,
      RequestMessage,
      ProjectAppModal,
      TableOverview,
      ProductFamilyCard,
      ProductFamilyTableOverview,
    },

    setup() {
      const {
        FETCH_FILTERS,
        FETCH_PRODUCT_FAMILY,
        FETCH_ALL_PRODUCT_FAMILIES,
        FETCH_PRODUCT_FILTERS,
        CLEAR_FILTERED_PRODUCTS,
        FETCH_ALL_PRODUCTS,
        FETCH_PRODUCTS_IN_PROD_FAMILY,
        FETCH_PROJECTS_IN_PRODUCT,
        FETCH_APP_IN_PRODUCT,
        FETCH_USERS,
        FETCH_APPLICATION_PURCHASE_ORDERS,
        FETCH_PROJECT_PURCHASE_ORDERS,
        ARE_PRODUCTS_FETCHED,
        FETCH_NOTIFICATIONS,
      } = VUEX_ACTIONS;
      const header = "Filter";
      const filtersLoaded = ref(false);
      const router = useRouter();
      const store = useStore();
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const costUnitsEnabled = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.cost_units_enabled;
        } else {
          return true;
        }
      });
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      const areProductsFetched = computed(() => store.getters.getAreProductsFetched);
      /* focusedProductId is the id of the product in the product overview header, selectedProductId is the id of the selected product in the product details */
      const focusedProductId = ref("0");
      const selectedProductId = ref("0");
      const product_po_forecast = ref(0);
      const product_invoice_actuals = ref(0);
      const isProductFocused = ref(false);
      const selectedProductFamily = ref("");
      const projectsInProduct = computed(() => store.getters.getProductProjects);
      const applicationsInProduct = computed(() => store.getters.getProductApplications);
      const isFilterSubmitted = ref(false);
      const projectsApplicationsList = computed(() => {
        const projects = projectsInProduct.value;
        const applications = applicationsInProduct.value;

        let projectsAndApplications = [];

        for (const project in projects) {
          const modifiedProject = { ...projects[project], type: "project" };
          projectsAndApplications.push(modifiedProject);
        }

        for (const application in applications) {
          const modifiedApplication = {
            ...applications[application],
            type: "application",
          };
          projectsAndApplications.push(modifiedApplication);
        }
        projectsAndApplications.sort((a, b) => a.name.localeCompare(b.name));
        return projectsAndApplications;
      });
      const productInOverviewName = ref("");
      const projAppFetched = computed(() => {
        if (store.getters.getProjectsInProductFetched && store.getters.getApplicationsInProductFetched) {
          return true;
        } else {
          return false;
        }
      });
      const projAppTarget = ref(0);
      const projAppForecast = ref(0);
      const projAppActuals = ref(0);
      const selectedPlatform = ref("");
      const isGridView = ref(true);
      const allProductFamilies = computed(() => store.getters.getAllProductFamilies);
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const canManagePlatform = computed(() => {
        return userRoles.value?.includes("manage-platform") ?? false;
      });

      const canSeePFOverview = computed(() => {
        return userRoles.value?.includes("edit-product-family") ?? false;
      });

      const showProductDetails = (productId: string) => {
        selectedProductId.value = productId;
        isProductFocused.value = false;
      };
      const isPlatform = ref(false);

      const showProjAppListInProduct = (productId: string, productName: string, productPOForecast: number, prodInvoiceActuals: number) => {
        sortingOption.value = "default";
        isProductFocused.value = true;
        store.dispatch(FETCH_PROJECTS_IN_PRODUCT, productId);
        store.dispatch(FETCH_APP_IN_PRODUCT, productId);
        productInOverviewName.value = productName;
        focusedProductId.value = productId;
        product_po_forecast.value = productPOForecast;
        product_invoice_actuals.value = prodInvoiceActuals;
        for (const product of products.value) {
          if (product.id === focusedProductId.value) {
            isPlatform.value = product.platform_cost != null;
          }
        }
      };

      const handleManageClick = () => {
        selectedPlatform.value = productInOverviewName.value;
        router.push({
          path: "/manage-platform",
          query: {
            platform: selectedPlatform.value,
          },
        });
      };

      const handleConsumersClick = () => {
        selectedPlatform.value = focusedProductId.value;
        router.push({
          path: "/platform-consumers",
          query: {
            platform: selectedPlatform.value,
          },
        });
      };

      store.dispatch(CLEAR_FILTERED_PRODUCTS);
      store.dispatch(FETCH_FILTERS);
      store.dispatch(FETCH_USERS);

      const filters = computed(() => store.getters.getFilters);

      const checkFiltersValue = () => {
        if (filters.value.length > 0) {
          filtersLoaded.value = true;
        }
      };

      checkFiltersValue();

      store.commit("setAreProductsFetched", false);
      const handleFilterSubmit = async (selectedFilter: ISelectedFilter[]) => {
        isProductFocused.value = false;
        if (selectedFilter.length > 0) {
          let str = "";
          for (const id in selectedFilter) {
            const flag = str === "" ? "?" : "&";
            let name = selectedFilter[id].name;

            if (selectedFilter[id].label === "Product Family") {
              selectedProductFamily.value = name;
            }

            if (name.includes("&")) {
              name = name.replace(/&/g, "%26");
            }

            if (selectedFilter[id].label === "Product Family") {
              if (name === "All") {
                str = "";
                store.dispatch(FETCH_ALL_PRODUCTS);
              } else {
                str += `${flag}productfamily=${name}`;
                store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedFilter[0].option);
                store.dispatch(FETCH_PRODUCT_FAMILY, selectedFilter[0].option);
              }
            }

            if (selectedFilter[id].label === "Region") {
              if (name !== "All") {
                str = str + `${flag}region=${name}`;
              }
            }
          }
          productInOverviewName.value = "";
          store.commit("setProjectsInProductFetched", false);
          store.commit("setApplicationsInProductFetched", false);
          projAppTarget.value = 0;
          projAppForecast.value = 0;
          projAppActuals.value = 0;
          totals.value.totalBUDG = 0;
          totals.value.totalFCST = 0;
          totals.value.totalACT = 0;
          store.commit(ARE_PRODUCTS_FETCHED, false);
          await store.dispatch(FETCH_PRODUCT_FILTERS, str);
          isFilterSubmitted.value = true;
          isPlatform.value = false;
          sortingOption.value = "default";
        }
      };
      const handleProductFamilyOverview = async (productFamily: IProductFamily) => {
        let name = productFamily.name;
        let str = "";
        const flag = str === "" ? "?" : "&";
        if (name.includes("&")) {
          name = name.replace(/&/g, "%26");
        }
        str += `${flag}productfamily=${name}`;
        isProductFocused.value = false;
        selectedProductFamily.value = productFamily.name;
        store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, productFamily.id);
        store.dispatch(FETCH_PRODUCT_FAMILY, productFamily.id);
        productInOverviewName.value = "";
        store.commit("setProjectsInProductFetched", false);
        store.commit("setApplicationsInProductFetched", false);
        projAppTarget.value = 0;
        projAppForecast.value = 0;
        projAppActuals.value = 0;
        totals.value.totalBUDG = 0;
        totals.value.totalFCST = 0;
        totals.value.totalACT = 0;
        store.commit(ARE_PRODUCTS_FETCHED, false);
        store.dispatch(FETCH_PRODUCT_FILTERS, str);
        isFilterSubmitted.value = true;
        isPlatform.value = false;
        sortingOption.value = "default";
      };
      const products = computed(() => store.getters.getProductFilters);
      const totals = computed(() => {
        let totalBUDG = 0;
        let totalFCST = 0;
        let totalACT = 0;
        for (const product of products.value) {
          let productPO = calculateProductPOForecast(product);

          let productInvoice = calculateProductInvoiceActuals(product);

          totalBUDG += Number(product.budget);
          totalFCST += Number(product.capitalforecast) + Number(product.expensesforecast) + Number(productPO);
          totalACT += Number(product.capitalactuals) + Number(product.expensesactuals) + Number(productInvoice);
        }
        return {
          totalBUDG,
          totalFCST,
          totalACT,
        };
      });
      const productFamily = computed(() => store.getters.getProductFamily);
      store.dispatch(FETCH_ALL_PRODUCT_FAMILIES);

      watch(filters, () => {
        checkFiltersValue();
      });

      watch([projectsApplicationsList], () => {
        projAppTarget.value = 0;
        projAppForecast.value = 0;
        projAppActuals.value = 0;
        for (const item in projectsApplicationsList.value) {
          projAppTarget.value = projAppTarget.value + Number(projectsApplicationsList.value[item].totalbudget);
          projAppForecast.value = projAppForecast.value + Number(projectsApplicationsList.value[item].totalforecast);
          projAppActuals.value = projAppActuals.value + Number(projectsApplicationsList.value[item].totalactuals);
        }
        projAppForecast.value += Number(product_po_forecast.value);
        projAppActuals.value += Number(product_invoice_actuals.value);
      });

      const showProjAppDetails = (id: string, projectType: string) => {
        if (projectType === "project") {
          store.dispatch(FETCH_PROJECT_PURCHASE_ORDERS, id);
        } else {
          store.dispatch(FETCH_APPLICATION_PURCHASE_ORDERS, id);
        }
      };

      const handleCUAllocationPercentageClick = () => {
        router.push(`/product-cost-unit-allocation?product=${focusedProductId.value}`);
      };

      const sortingOption = ref("default");
      const sortedProductFamilies = computed(() => {
        switch (sortingOption.value) {
          case "default":
            return [...allProductFamilies.value].sort((a, b) => a.name.localeCompare(b.name));
          case "z-a":
            return [...allProductFamilies.value].sort((a, b) => b.name.localeCompare(a.name));
          case "budget-descending":
            return [...allProductFamilies.value].sort((a, b) => b.budget - a.budget);
          case "budget-ascending":
            return [...allProductFamilies.value].sort((a, b) => a.budget - b.budget);
          case "actuals-descending":
            return [...allProductFamilies.value].sort((a, b) => Number(b.actuals) + Number(b.total_product_actuals) - (Number(a.actuals) + Number(a.total_product_actuals)));
          case "actuals-ascending":
            return [...allProductFamilies.value].sort((a, b) => Number(a.actuals) + Number(a.total_product_actuals) - (Number(b.actuals) + Number(b.total_product_actuals)));
          default:
            return allProductFamilies.value;
        }
      });

      const sortedProducts = computed(() => {
        switch (sortingOption.value) {
          case "default":
            return [...products.value].sort((a, b) => a.name.localeCompare(b.name));
          case "z-a":
            return [...products.value].sort((a, b) => b.name.localeCompare(a.name));
          case "budget-descending":
            return [...products.value].sort((a, b) => b.budget - a.budget);
          case "budget-ascending":
            return [...products.value].sort((a, b) => a.budget - b.budget);
          case "actuals-descending":
            return [...products.value].sort((a, b) => Number(b.actuals) + calculateProductInvoiceActuals(b) - (Number(a.actuals) + calculateProductInvoiceActuals(a)));
          case "actuals-ascending":
            return [...products.value].sort((a, b) => Number(a.actuals) + calculateProductInvoiceActuals(a) - (Number(b.actuals) + calculateProductInvoiceActuals(b)));
          default:
            return products.value;
        }
      });

      const sortedProjectsApplicationsList = computed(() => {
        switch (sortingOption.value) {
          case "default":
            return [...projectsApplicationsList.value].sort((a, b) => a.name.localeCompare(b.name));
          case "z-a":
            return [...projectsApplicationsList.value].sort((a, b) => b.name.localeCompare(a.name));
          case "budget-descending":
            return [...projectsApplicationsList.value].sort((a, b) => b.totalbudget - a.totalbudget);
          case "budget-ascending":
            return [...projectsApplicationsList.value].sort((a, b) => a.totalbudget - b.totalbudget);
          case "actuals-descending":
            return [...projectsApplicationsList.value].sort((a, b) => b.totalactuals - a.totalactuals);
          case "actuals-ascending":
            return [...projectsApplicationsList.value].sort((a, b) => a.totalactuals - b.totalactuals);
          default:
            return projectsApplicationsList.value;
        }
      });

      const handleReturnToPFOverview = () => {
        selectedProductFamily.value = "";
        productInOverviewName.value = "";
        isFilterSubmitted.value = false;
        store.commit("setAreProductsFetched", false);
      };

      return {
        header,
        filters,
        handleFilterSubmit,
        filtersLoaded,
        products,
        productFamily,
        showProductDetails,
        selectedProductId,
        totals,
        selectedProductFamily,
        areProductsFetched,
        showProjAppListInProduct,
        projectsApplicationsList,
        productInOverviewName,
        projAppFetched,
        projAppTarget,
        projAppForecast,
        projAppActuals,
        showProjAppDetails,
        isFilterSubmitted,
        handleCUAllocationPercentageClick,
        focusedProductId,
        handleManageClick,
        isPlatform,
        handleConsumersClick,
        canManagePlatform,
        isGridView,
        isProductFocused,
        product_po_forecast,
        product_invoice_actuals,
        costUnitsEnabled,
        allProductFamilies,
        handleProductFamilyOverview,
        canSeePFOverview,
        sortingOption,
        sortedProductFamilies,
        sortedProducts,
        sortedProjectsApplicationsList,
        handleReturnToPFOverview,
      };
    },
  });
</script>
