<template>
  <RequestMessage></RequestMessage>
  <div v-if="!areRequestsFetched" class="cst-spinner" data-testid="vrequests-spinner"></div>
  <div v-else class="v-requests">
    <h1 class="v-requests__title" data-testid="vrequests-open-requests-label">Open requests</h1>
    <div v-if="isRequestListEmpty">
      <h1 class="v-requests__title" data-testid="vrequests-norequests">You have no Requests pending...</h1>
    </div>
    <div v-else class="v-requests__table-container" data-testid="v-requests__table">
      <div class="v-requests__table-container--search">
        <C_Search :placeholder="'Search by description...'" @input="filterOpenRequests($event)" />
      </div>
      <div class="v-requests__table-wrapper" v-if="filteredRequests.length > 0">
        <table class="v-polist__table v-polist__list-table" aria-describedby="Table of open requests">
          <thead>
            <tr class="v-polist__table-head-row">
              <th>Request ID</th>
              <th v-if="supplierEnabled">Supplier</th>
              <th v-if="sbuEnabled">SBU</th>
              <th>Description</th>
              <th>PR Owner</th>
              <th>Company</th>
              <th>Company Contact</th>
              <th>Amount</th>
              <th>Current Year</th>
              <th>Next Year</th>
              <th>Start Date</th>
              <th>Delivery Date</th>
              <th>Status</th>
              <th v-if="!showManagementBtns">Delete</th>
              <th v-if="showManagementBtns">Approve</th>
              <th v-if="showManagementBtns">Decline</th>
              <th v-if="prJustificationEnabled && isFamilyManager">Justify</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredRequests" :key="item.id" class="v-polist__table-row" data-testid="vrequests-request">
              <td class="vpolist">{{ item.id }}</td>
              <td class="vpolist" v-if="supplierEnabled">
                {{ item.suppliername }}
              </td>
              <td class="vpolist" v-if="sbuEnabled">
                {{ item.sbuname }}
              </td>
              <td class="vpolist">
                {{ item.ordertext }}
              </td>
              <td class="vpolist">
                {{ item.prownername }}
              </td>
              <td class="vpolist">
                {{ item.company_name }}
              </td>
              <td class="vpolist">
                {{ item.contact_name }}
              </td>
              <td class="vpolist">
                {{ formatNumber((Number(item.orderamount) * currencyRate).toFixed(2)) }}
                {{ currency }}
              </td>
              <td class="vpolist">
                {{ formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate).toFixed(2)) }}
                {{ currency }}
              </td>
              <td class="vpolist">
                {{ formatNumber(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate).toFixed(2)) }}
                {{ currency }}
              </td>
              <td class="vpolist">
                {{ formatDate(item.podate) }}
              </td>
              <td class="vpolist">
                {{ formatDate(item.deliverydate) }}
              </td>
              <td v-if="isExpired(item.deliverydate)" title="This PR will expire in less than 8 weeks!">
                <img :src="'./warning.png'" alt="warning button icon" class="v-requests__table-data-warning" />
              </td>
              <td v-if="!isExpired(item.deliverydate)" title="" class="v-requests__table"></td>

              <td v-if="!showManagementBtns">
                <button @click="handleDecline({ itemId: item.id, message: item.message })" type="button" class="c-requestbtns cst-button-danger c-requestbtns--no-margin" data-testid="deletepr-btn">
                  Delete
                  <img :src="'./trash.svg'" alt="delete button icon" />
                </button>
              </td>
              <td v-if="showManagementBtns">
                <button
                  @click="showConfirmModal(item.id, 'Approve')"
                  type="button"
                  class="c-requestbtns cst-button-primary c-requestbtns--no-margin"
                  :disabled="item?.justification_status == 'Proposed'"
                  :class="{
                    'c-requestbtns--disabled': item?.justification_status == 'Proposed',
                  }"
                  :title="item?.justification_status == 'Proposed' ? 'Must be signed by a Product Family Manager' : ''"
                  data-testid="approvepr-btn"
                >
                  Approve

                  <img
                    :src="'./accept.svg'"
                    alt="accept button icon"
                    :style="{
                      filter: item?.justification_status == 'Proposed' ? 'invert()' : '',
                    }"
                  />
                </button>
              </td>
              <td v-if="showManagementBtns">
                <button
                  @click="showConfirmModal(item.id, 'Do you want to decline the request?')"
                  type="button"
                  class="c-requestbtns cst-button-danger c-requestbtns--no-margin"
                  data-testid="declinepr-btn"
                  :disabled="item?.justification_status == 'Proposed'"
                  :class="{
                    'c-requestbtns--disabled': item?.justification_status == 'Proposed',
                  }"
                  :title="item?.justification_status == 'Proposed' ? 'Must be signed by a Product Family Manager' : ''"
                >
                  Decline
                  <img
                    :src="'./decline.svg'"
                    alt="decline button icon"
                    :style="{
                      filter: item?.justification_status == 'Proposed' ? 'grayscale(100%)' : '',
                    }"
                  />
                </button>
              </td>
              <td v-if="prJustificationEnabled && isFamilyManager">
                <div v-if="item.justification_status == 'Proposed'">
                  <button @click="showJustificationModal(item.id, item.orderamount, 'Review')" type="button" class="c-requestbtns c-requestbtns-secondary-details c-requestbtns--no-margin" data-testid="review-justification-btn">
                    Review
                    <svg fill="#000000" width="16px" height="16px" viewBox="-1 0 19 19" class="cf-icon-svg">
                      <path
                        d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                      />
                    </svg>
                  </button>
                </div>
                <div v-else>-</div>
              </td>
              <td>
                <button @click="handleDetails(item)" class="c-requestbtns c-requestbtns-secondary-details c-requestbtns--no-margin" alt="details button icon" data-testid="detailspr-btn">
                  Show
                  <svg fill="#000000" width="16px" height="16px" viewBox="-1 0 19 19" class="cf-icon-svg">
                    <path
                      d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>No such request has been found.</p>
      </div>
      <C_Pagination v-if="totalPages > 1" :totalPages="totalPages" :itemList="requests" @paginateList="paginateList" data-testid="pages-indicator" />
    </div>
    <RequestDetails
      :selectedPRListItems="selectedPRListItems"
      v-if="selectedItemId !== undefined"
      :selectedItemId="selectedItemId"
      @decline="showConfirmModal(selectedItemId, 'Do you want to decline the request?')"
      @approve="showConfirmModal(selectedItemId, 'Approve')"
      :show-c-t-a="showCTA"
      data-testid="vrequests-request-details"
    ></RequestDetails>
    <RequestConfirmModal :itemId="requestID" :action="proces" @decline="handleDecline" @approve="handleAccept"></RequestConfirmModal>
    <C_JustificationModal :itemId="requestID" :pramount="Number(focusedRequestAmount)"></C_JustificationModal>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed, watch } from "vue";
  import tableLabels from "@/utils/assets/requestTable.json";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import RequestConfirmModal from "../components/modals/C_RequestConfirmModal.vue";
  import RequestDetails from "../components/modals/C_RequestDetails.vue";
  import RequestMessage from "../components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { addWeeks, isBefore } from "date-fns";
  import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import C_JustificationModal from "@/components/modals/C_JustificationModal.vue";
  import C_Search from "@/components/C_Search.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  const { FETCH_LIST_ITEMS, SHOW_PR_DETAILS_VIEW, CHANGE_CONFIRM_MODAL_VIEW, FETCH_PURCHASE_REQUESTS, DECLINE_PURCHASE_REQUEST, SWITCH_TO_PURCHASE_ORDER, UPDATE_REQUEST_MESSAGE, FETCH_SBU, FETCH_NOTIFICATIONS, FETCH_ARCHIVE, CHANGE_JUSTIFICATION_MODAL_VIEW } =
    VUEX_ACTIONS;
  const labels = ref<string[]>([]);
  const selectedItemId = ref<number | undefined>(undefined);
  const requestID = ref<number | undefined>(undefined);
  const focusedRequestAmount = ref<number>(0);
  const store = useStore();
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currentUserRoles = computed(() => store.getters.getCurrentUserRoles);
  const currentUserGroups = computed(() => store.getters.getCurrentUserGroups);
  const proces = ref("");
  const showManagementBtns = ref();
  const selectedPRListItems = ref([]);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  const isFamilyManager = computed(() => {
    return currentUserGroups.value?.includes("Product Family Manager");
  });
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });

  const prJustificationEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_justification_enabled;
    } else {
      return false;
    }
  });

  store.dispatch(FETCH_SBU);
  store.dispatch(FETCH_ARCHIVE);

  const requests = computed(() => store.getters.getPurchaseRequests);
  const filteredRequests = ref(requests.value?.slice(0, 10));
  const paginatedList = ref(requests.value?.slice(0, 10));
  const areRequestsFetched = computed(() => store.getters.getAreRequestsFetched);

  const totalPages = computed(() => {
    if (requests.value.length > 10 && requests.value.length % 10 != 0) {
      return Math.floor(requests.value.length / 10) + 1;
    } else {
      return requests.value.length / 10;
    }
  });
  const isRequestListEmpty = computed(() => {
    if (requests.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const showCTA = computed(() => {
    if (currentUserRoles.value?.includes("approve-purchase-request")) {
      return true;
    } else {
      return false;
    }
  });

  onMounted(() => {
    labels.value = tableLabels.map((item: { label: string }) => item.label);
    if (supplierEnabled.value) {
      labels.value.splice(1, 0, "Supplier");
    }
    if (sbuEnabled.value) {
      labels.value.splice(2, 0, "SBU");
    }
    if (currentUserRoles.value?.includes("approve-purchase-request")) {
      showManagementBtns.value = true;
    } else {
      showManagementBtns.value = false;
    }
  });

  const expirationDate = computed(() => {
    return addWeeks(new Date(), 8);
  });

  const isExpired = (deliveryDate: string) => {
    const deliveryDateObj = new Date(deliveryDate);
    return isBefore(deliveryDateObj, expirationDate.value);
  };

  const showConfirmModal = async (itemId: number, action: string) => {
    requestID.value = Number(itemId);
    store.dispatch(SHOW_PR_DETAILS_VIEW, false);
    await store.dispatch(FETCH_LIST_ITEMS, itemId);
    proces.value = action;
    store.dispatch(CHANGE_CONFIRM_MODAL_VIEW, true);
  };

  const showJustificationModal = async (itemId: number, itemAmount: number, action: string) => {
    requestID.value = Number(itemId);
    focusedRequestAmount.value = itemAmount;
    proces.value = action;
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, true);
  };

  const handleDecline = async (data: { itemId: number; message: string }) => {
    if (data.itemId !== null) {
      if (!currentUserRoles.value?.includes("approve-purchase-request")) {
        store.dispatch(UPDATE_REQUEST_MESSAGE, "Request deleted successfully");
      } else {
        store.dispatch(UPDATE_REQUEST_MESSAGE, "Request declined successfully");
      }
      await store.dispatch(DECLINE_PURCHASE_REQUEST, {
        itemId: data.itemId,
        message: data.message,
      });
      store.dispatch(CHANGE_CONFIRM_MODAL_VIEW, false);
      requestID.value = undefined;
      selectedItemId.value = undefined;
    }
  };

  const handleAccept = async (data: { itemId: number; orderNumber: string; companyContactId: number }) => {
    if (data.itemId !== undefined && data.orderNumber) {
      store.dispatch(SWITCH_TO_PURCHASE_ORDER, {
        itemId: data.itemId,
        orderNumber: data.orderNumber,
        companyContactId: data.companyContactId,
      });
      store.dispatch(UPDATE_REQUEST_MESSAGE, "Request accepted successfully!");

      requestID.value = undefined;
      selectedItemId.value = undefined;
    } else {
      store.dispatch(UPDATE_REQUEST_MESSAGE, "ERROR: Order number cannot be empty!");
    }
  };

  const handleDetails = (item: IPurchaseRequest) => {
    store.dispatch(SHOW_PR_DETAILS_VIEW, true);
    selectedItemId.value = Number(item.id);
    selectedPRListItems.value = item.listitems;
  };

  const fetchRequests = () => {
    store.dispatch(FETCH_PURCHASE_REQUESTS);

    labels.value = tableLabels.map((item: { label: string }) => item.label);
    if (sbuEnabled.value) {
      labels.value.splice(2, 0, "SBU");
    }
    if (currentUserRoles.value.includes("approve-purchase-request")) {
      showManagementBtns.value = true;
    } else {
      showManagementBtns.value = false;
    }
  };
  fetchRequests();

  const filterOpenRequests = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredRequests.value = requests.value.filter((request: IPurchaseRequest) => {
      if (request?.ordertext) return request?.ordertext.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });

    if (searchTerm === "") {
      filteredRequests.value = paginatedList.value;
    }
  };

  watch([requests], () => {
    paginatedList.value = requests.value?.slice(0, 10);
    filteredRequests.value = requests.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IPurchaseRequest>) => {
    filteredRequests.value = filteredList;
    paginatedList.value = filteredList;
  };
</script>
