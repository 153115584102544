import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "createcompany__wrapper",
  "data-testid": "createcontact-wrapper"
}
const _hoisted_2 = { class: "cst-inputbox" }
const _hoisted_3 = { class: "cst-inputbox--body" }
const _hoisted_4 = { class: "cst-inputbox__form cst-inputbox__form--column-wrapper" }
const _hoisted_5 = { class: "createcompany__fields-row" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = { class: "cst-input-field" }
const _hoisted_10 = { class: "cst-input-field" }

import RequestMessage from "@/components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { ref, computed } from "vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { ICompanyContact } from "@/utils/interfaces/ICompanyContact";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_CreateContact',
  props: {
    fromCreateCompany: {
      type: Boolean,
      default: false,
    },
  },
  setup(__props) {

  const props = __props;

  const { CREATE_COMPANY_CONTACT, FETCH_ALL_COMPANIES } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const contactName = ref("");
  const contactPhone = ref("");
  const contactEmail = ref("");
  const allCompanies = computed(() => store.getters.getAllCompanies);
  const previouslyCreatedCompany = computed(() => store.getters.getCreatedCompany);
  const selectedCompany = ref("");
  store.dispatch(FETCH_ALL_COMPANIES);

  if (props.fromCreateCompany && previouslyCreatedCompany.value) {
    selectedCompany.value = previouslyCreatedCompany.value.id;
  }

  // Route guard
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  if (!userRoles.value.includes("edit-product-family")) {
    router.push("/");
  }

  const handleCompanyContactCreation = () => {
    const companyContact: ICompanyContact = {
      name: contactName.value,
      company_id: Number(selectedCompany.value),
    };

    if (contactPhone.value) {
      companyContact.phone = contactPhone.value;
    }
    if (contactEmail.value) {
      companyContact.email = contactEmail.value;
    }
    store.dispatch(CREATE_COMPANY_CONTACT, companyContact);
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
          _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Add a new Company Contact")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(handleCompanyContactCreation, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", { for: "company" }, [
                    _createTextVNode("Company:"),
                    _createElementVNode("span", { style: {"color":"red"} }, "*")
                  ], -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    id: "company",
                    placeholder: "Select Country",
                    required: "",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCompany).value = $event)),
                    "data-testid": "contactcompany-select"
                  }, [
                    _cache[4] || (_cache[4] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a company", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allCompanies.value, (company) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: company.id,
                        value: company.id,
                        "data-testid": "createcontact-companies-options"
                      }, _toDisplayString(company.name), 9, _hoisted_7))
                    }), 128))
                  ], 512), [
                    [
                      _vModelSelect,
                      selectedCompany.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", { for: "contact-name" }, [
                    _createTextVNode("Name:"),
                    _createElementVNode("span", { style: {"color":"red"} }, "*")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "text",
                    id: "contact-name",
                    placeholder: "Enter Contact Name",
                    required: "",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((contactName).value = $event)),
                    "data-testid": "createcontact-name"
                  }, null, 512), [
                    [
                      _vModelText,
                      contactName.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "contact-phone" }, "Phone:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "tel",
                    id: "contact-phone",
                    placeholder: "Enter Phone",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((contactPhone).value = $event)),
                    "data-testid": "createcontact-phone"
                  }, null, 512), [
                    [
                      _vModelText,
                      contactPhone.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", { for: "contact-email" }, "Email: ", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "text",
                    id: "contact-email",
                    placeholder: "Enter Email",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((contactEmail).value = $event)),
                    "data-testid": "createcontact-email"
                  }, null, 512), [
                    [
                      _vModelText,
                      contactEmail.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ])
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "cst-inputbox--flex-wrapper" }, [
                _createElementVNode("button", {
                  class: "submit-button cst-button cst-button-primary",
                  type: "submit",
                  "data-testid": "createcontact-submit-button"
                }, "Submit")
              ], -1))
            ])
          ], 32)
        ])
      ])
    ])
  ], 64))
}
}

})