import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-requestconfirm__wrapper",
  "data-testid": "requestconfirm-modal-wrapper"
}
const _hoisted_2 = { class: "c-requestconfirm__container" }
const _hoisted_3 = { class: "c-requestconfirm__top-container" }
const _hoisted_4 = { class: "c-requestconfirm__title-div" }
const _hoisted_5 = { class: "c-requestconfirm__title" }
const _hoisted_6 = { class: "c-requestconfirm__items-group" }
const _hoisted_7 = {
  key: 0,
  class: "c-requestconfirm__item"
}
const _hoisted_8 = { "data-testid": "requestid" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "c-requestconfirm__item" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "c-requestconfirm__item" }
const _hoisted_13 = {
  key: 0,
  class: "c-requestconfirm__note"
}
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { class: "c-requestconfirm__listitem-container" }
const _hoisted_16 = {
  key: 0,
  class: "c-requestconfirm__listitem"
}
const _hoisted_17 = { class: "c-requestconfirm__listitem-select" }
const _hoisted_18 = ["onChange"]
const _hoisted_19 = { class: "c-requestconfirm__cta" }
const _hoisted_20 = { class: "c-requestconfirm__cta--wrapper" }
const _hoisted_21 = { class: "c-requestconfirm__cta-buttons" }
const _hoisted_22 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showConfirmModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.action), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  (_ctx.selectedItem?.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _cache[5] || (_cache[5] = _createElementVNode("span", null, "Request ID: ", -1)),
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.selectedItem.id), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.props.action !== 'Do you want to decline the request?')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "c-requestconfirm__label" }, "Company Contact: ", -1)),
                          _withDirectives(_createElementVNode("select", {
                            class: "cst-select-field",
                            required: "",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCompanyContact) = $event)),
                            "data-testid": "company-contact-dropdown"
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyContacts, (contact) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: contact.id,
                                value: contact.id
                              }, _toDisplayString(contact.name), 9, _hoisted_11))
                            }), 128))
                          ], 512), [
                            [_vModelSelect, _ctx.selectedCompanyContact]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "c-requestconfirm__label" }, "Order number: ", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "cst-input",
                            type: "text",
                            id: "order-number",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orderNumberInput) = $event)),
                            maxlength: "32",
                            "data-testid": "ordernumber",
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.orderNumberInput]
                          ])
                        ]),
                        (_ctx.orderNumberInput.length < 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, "*Order number has to be entered"))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.undefinedFlag)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _cache[11] || (_cache[11] = _createElementVNode("p", {
                          class: "c-requestconfirm__undefined-message",
                          "data-testid": "undefined-message"
                        }, [
                          _createTextVNode(" This request has some list items with cost type \""),
                          _createElementVNode("strong", null, "Undefined"),
                          _createTextVNode("\"."),
                          _createElementVNode("br"),
                          _createTextVNode(" You must change the "),
                          _createElementVNode("strong", null, "Undefined"),
                          _createTextVNode(" cost type to a specific cost type: ")
                        ], -1)),
                        _createElementVNode("div", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItems, (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.id
                            }, [
                              (item.costtype === `Undefined`)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createElementVNode("p", null, [
                                      _createElementVNode("b", null, _toDisplayString(item.name), 1)
                                    ]),
                                    _createElementVNode("p", null, _toDisplayString(item.amount + " " + item.currency), 1),
                                    _cache[9] || (_cache[9] = _createElementVNode("p", null, [
                                      _createElementVNode("b", null, "Current cost type:")
                                    ], -1)),
                                    _createElementVNode("p", null, _toDisplayString(item.costtype), 1),
                                    _cache[10] || (_cache[10] = _createElementVNode("p", null, [
                                      _createElementVNode("b", null, "New cost type:")
                                    ], -1)),
                                    _createElementVNode("div", _hoisted_17, [
                                      _createElementVNode("select", {
                                        class: "cst-select-field",
                                        onChange: ($event: any) => (_ctx.handleTypeChange($event, item.id)),
                                        id: "select-costtype"
                                      }, _cache[8] || (_cache[8] = [
                                        _createElementVNode("option", {
                                          disabled: "",
                                          selected: "",
                                          value: ""
                                        }, "Select a Cost Type", -1),
                                        _createElementVNode("option", null, "Capital Infrastructure", -1),
                                        _createElementVNode("option", null, "Capital Maintenance", -1),
                                        _createElementVNode("option", null, "Capital Enhancement", -1),
                                        _createElementVNode("option", null, "Expense Infrastructure", -1),
                                        _createElementVNode("option", null, "Expense Maintenance", -1),
                                        _createElementVNode("option", null, "Expense Enhancement", -1)
                                      ]), 40, _hoisted_18)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              (_ctx.props.action === 'Do you want to decline the request?')
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 0,
                    type: "text",
                    placeholder: "Message...",
                    id: "messageinput",
                    class: "c-requestconfirm__cta--wrapper__message",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.messageInput) = $event)),
                    "data-testid": "messageinput"
                  }, null, 512)), [
                    [_vModelText, _ctx.messageInput]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("button", {
                type: "button",
                disabled: _ctx.undefinedFlag || _ctx.checkInputLength(),
                class: "cst-button cst-button-primary c-requestconfirm__confirm-btn",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.confirmAction && _ctx.confirmAction(...args))),
                "data-testid": "confirmbtn"
              }, "Confirm", 8, _hoisted_22),
              _createElementVNode("button", {
                class: "cst-button c-requestconfirm__cancel-btn",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                "data-testid": "cancelbtn"
              }, "Cancel")
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}