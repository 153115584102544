import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { ICompany } from "@/utils/interfaces/ICompany";
import { handleAxiosError } from "@/utils/helpers/axiosErrorHandler";
import { ICompanyContact } from "@/utils/interfaces/ICompanyContact";

interface CompaniesState {
  allCompanies: ICompany[];
  areCompaniesFetched: boolean;
  specificCompany: ICompany | null;
  isCompanyCreated: boolean;
  createdCompany: ICompany | null;
  companyContacts: ICompanyContact[];
  contactsFetched: boolean;
  isCompanyRowExpanded: boolean;
}

interface CompaniesContext {
  commit: Commit;
  dispatch: Dispatch;
  state: CompaniesState;
}

const state: CompaniesState = {
  allCompanies: [],
  areCompaniesFetched: false,
  specificCompany: null,
  isCompanyCreated: false,
  createdCompany: null,
  companyContacts: [],
  contactsFetched: false,
  isCompanyRowExpanded: false,
};

const mutations = {
  setAllCompanies(state: CompaniesState, payload: ICompany[]) {
    state.allCompanies = payload;
  },
  setAreCompaniesFetched(state: CompaniesState, payload: boolean) {
    state.areCompaniesFetched = payload;
  },
  setSpecificCompany(state: CompaniesState, payload: ICompany) {
    state.specificCompany = payload;
  },
  setIsCompanyCreated(state: CompaniesState, payload: boolean) {
    state.isCompanyCreated = payload;
  },
  setCreatedCompany(state: CompaniesState, payload: ICompany) {
    state.createdCompany = payload;
  },
  setCompanyContacts(state: CompaniesState, payload: ICompanyContact[]) {
    state.companyContacts = payload;
  },
  setContactsFetched(state: CompaniesState, payload: boolean) {
    state.contactsFetched = payload;
  },
  setIsCompanyRowExpanded(state: CompaniesState, payload: boolean) {
    state.isCompanyRowExpanded = payload;
  },
};

const getters = {
  getAllCompanies: (state: CompaniesState) => state.allCompanies,
  getAreCompaniesFetched: (state: CompaniesState) => state.areCompaniesFetched,
  getSpecificCompany: (state: CompaniesState) => state.specificCompany,
  getIsCompanyCreated: (state: CompaniesState) => state.isCompanyCreated,
  getCreatedCompany: (state: CompaniesState) => state.createdCompany,
  getCompanyContacts: (state: CompaniesState) => state.companyContacts,
  getContactsFetched: (state: CompaniesState) => state.contactsFetched,
  getIsCompanyRowExpanded: (state: CompaniesState) => state.isCompanyRowExpanded,
};

const actions = {
  fetchAllCompanies(context: CompaniesContext) {
    AXIOS_REQUEST("GET", "/companies")
      .then((response) => {
        context.commit("setAllCompanies", response.data);
        context.commit("setAreCompaniesFetched", true);
      })
      .catch((error) => {
        handleAxiosError(context, error, "Error fetching companies");
        console.error(error);
      });
  },

  fetchSpecificCompany(context: CompaniesContext, id: number) {
    AXIOS_REQUEST("GET", `/companies/${id}`)
      .then((response) => {
        context.commit("setSpecificCompany", response.data);
        context.commit("setCompanyContacts", response.data.contacts);
        context.commit("setContactsFetched", true);
      })
      .catch((error) => {
        handleAxiosError(context, error, "Error fetching company");
        console.error(error);
      });
  },

  createCompany(context: CompaniesContext, company: ICompany) {
    AXIOS_REQUEST("POST", "/companies", company)
      .then((response) => {
        context.dispatch("updateRequestMessage", `SUCCESS: Company created!`);
        context.commit("setCreatedCompany", response.data);
        context.commit("setIsCompanyCreated", true);
      })
      .catch((error) => {
        handleAxiosError(context, error, "Error creating company");
        console.error(error);
      });
  },
};

const companiesModule = {
  state,
  getters,
  mutations,
  actions,
};

export default companiesModule;
