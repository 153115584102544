<template>
  <div :class="isCompany ? 'search__wrapperCompany' : 'search__wrapper'">
    <input class="search__input" type="text" id="search-input" required maxlength="40" data-testid="search" :placeholder="props.placeholder" />
  </div>
</template>
<script lang="ts" setup>
  import { defineProps } from "vue";
  const props = defineProps({
    placeholder: {
      type: String,
      default: "",
    },
    isCompany: {
      type: Boolean,
    },
  });
</script>
