<template>
  <RequestMessage></RequestMessage>
  <div class="v-polist__main-container">
    <h1 class="v-polist__title-all" data-testid="v-expiration__heading">Expiration List of Purchase Orders</h1>
    <div v-if="!areOrdersFetched" class="cst-spinner" data-testid="v-expiration-spinner"></div>
    <div v-else>
      <h3 v-if="showNoOrdersMessage" class="v-polist__no-orders-text">No expiring Purchase Orders to show</h3>
      <div v-else>
        <div class="v-polist__expiration-list">
          <div class="v-polist__filter">
            <h5>Choose a Product Family</h5>

            <select v-model="selectedProductFamilyLocal" data-testid="expiration-select-prodfamily">
              <option value="" selected>Show All</option>
              <option v-for="(productFamily, index) in productFamilies" :key="index">
                {{ productFamily }}
              </option>
            </select>
          </div>
        </div>
        <div class="v-polist__table-container" data-testid="v-expiration__table">
          <div class="v-polist__table-wrapper">
            <table aria-describedby="Table of Purchase Orders" class="v-polist__table v-polist__list-table">
              <thead>
                <tr class="v-polist__table-head-row">
                  <th data-testid="v-expiration-listpo-ordernumber-sort-ordernum" @click="sortByColumn('ordernumber', orders)">Order Number</th>
                  <th data-testid="v-expiration-ordernumber-sort-status" @click="sortByColumn('status', orders)">Status</th>
                  <th data-testid="v-expiration-listpo-ordernumber-sort-prownername" @click="sortByColumn('prownername', orders)">Owner</th>
                  <th data-testid="v-expiration-ordernumber-sort-companycontact" @click="sortByColumn('contact_name', orders)">Company Contact</th>
                  <th data-testid="v-expiration-ordernumber-sort-ordertext" @click="sortByColumn('ordertext', orders)">Description</th>
                  <th data-testid="v-expiration-ordernumber-sort-orderamount" @click="sortByColumn('orderamount', orders)">PO Amount</th>
                  <th data-testid="v-expiration-ordernumber-sort-amountCurrentYear" @click="sortByColumn('orderamount', orders)">Current year</th>
                  <th data-testid="v-expiration-ordernumber-sort-amountNextYear" @click="sortByColumn('orderamount', orders)">Next Year</th>
                  <th data-testid="v-expiration-ordernumber-sort-remainingamount" @click="sortByColumn('po_remaining_amount', orders)">Remaining Amount</th>
                  <th data-testid="v-expiration-ordernumber-sort-currency" @click="sortByColumn('currency', orders)">Currency</th>
                  <th data-testid="v-expiration-ordernumber-sort-deliverydate" @click="sortByColumn('deliverydate', orders)">Delivery Date (End Date)</th>
                  <th>Details</th>
                  <th v-if="hasPermission">Renew</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedPOExpirationList" :key="index" class="v-polist__table-row">
                  <td>{{ item.ordernumber }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.prownername }}</td>
                  <td>{{ item.contact_name }}</td>
                  <td data-testid="expiration-ordertext">
                    {{ item.ordertext }}
                  </td>
                  <td>{{ formatNumber((item.orderamount * currencyRate).toFixed(2)) }}</td>
                  <td>{{ formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate).toFixed(2)) }}</td>
                  <td>{{ formatNumber(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate).toFixed(2)) }}</td>
                  <td>{{ formatNumber((item.po_remaining_amount * currencyRate).toFixed(2)) }}</td>
                  <td>{{ item.currency }}</td>
                  <td>
                    {{ item.deliverydate ? formatDate(item.deliverydate) : "" }}
                  </td>
                  <td>
                    <button title="Show" class="c-requestbtns c-requestbtns-secondary-show" data-testid="v-expirationpo-detailsbtn" @click="showPoDetails(item)">
                      <p>Show</p>
                      <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                        <path
                          d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                        />
                      </svg>
                    </button>
                  </td>
                  <td v-if="hasPermission">
                    <button title="Renew" class="c-requestbtns c-requestbtns-secondary-renew" data-testid="v-expirationpo-renewbtn" @click="handleRenew(item)">
                      <p>Renew</p>
                      <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 20.5001C16.6944 20.5001 20.5 16.6945 20.5 12.0001C20.5 9.17456 19.1213 6.67103 17 5.1255M13 22.4001L11 20.4001L13 18.4001M12 3.5001C7.30558 3.5001 3.5 7.30568 3.5 12.0001C3.5 14.8256 4.87867 17.3292 7 18.8747M11 5.6001L13 3.6001L11 1.6001"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <C_Pagination v-if="totalPages > 1" :totalPages="totalPages" :itemList="orders" @paginateList="paginateList" data-testid="pages-indicator" />
          </div>
        </div>
      </div>
    </div>
    <ListPODetails :selectedPOName="selectedPOName" :selectedItem="selectedItem" :supplierNameEnabled="supplierEnabled" />
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from "vue";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";
  import { sortByColumn } from "@/utils/helpers/sortByColumn";
  import { useStore } from "vuex";
  import ListPODetails from "@/components/modals/C_ListPODetails.vue";
  import { useRouter } from "vue-router";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  const { CHANGE_PO_DETAILS_VIEW, FETCH_PURCHASE_ORDERS, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const selectedProductFamilyLocal = ref("");
  const selectedPOName = ref("");
  const orders = computed(() => store.getters.getPurchaseOrders);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const selectedItem = ref({} as IUserOrders);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const hasPermission = computed(() => userRoles.value.includes("edit-purchase-request"));
  const areOrdersFetched = computed(() => store.getters.getAreOrdersFetched);
  const paginatedList = ref(orders.value?.slice(0, 10));
  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const fetchPurchaseOrders = () => {
    store.dispatch(FETCH_PURCHASE_ORDERS);
  };

  fetchPurchaseOrders();

  const filteredOrdersByDate = computed(() => {
    if (!orders.value) {
      return [];
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const previousMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1; // wrapping around to the previous year

    const ordersByDate = orders.value.filter((order: { deliverydate: string | number | Date }) => {
      if (!order.deliverydate) {
        return false;
      }
      const orderDate = new Date(order.deliverydate);
      const orderYear = orderDate.getFullYear();
      const orderMonth = orderDate.getMonth();

      return orderYear === currentYear && (orderMonth === currentMonth || orderMonth === previousMonth);
    });
    return ordersByDate;
  });

  const showNoOrdersMessage = computed(() => {
    if (filteredOrdersByDate.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const productFamilies = computed(() => {
    // unique product family names for the current user
    const uniqueProductFamilies = new Set(filteredOrdersByDate.value.flatMap((order: IUserOrders) => order.listitems.map((listItem: { productfamilyname: string }) => listItem.productfamilyname)));
    return Array.from(uniqueProductFamilies);
  });

  const filteredOrders = computed<IUserOrders[]>(() => {
    const isShowAll = selectedProductFamilyLocal.value === "";
    if (isShowAll) {
      // If no product family is selected, return filteredOrdersByDate as is
      return filteredOrdersByDate.value;
    }
    return filteredOrdersByDate.value.filter((order: IUserOrders) => order.listitems.some((listItem: { productfamilyname: string }) => listItem.productfamilyname === selectedProductFamilyLocal.value));
  });

  const paginatedPOExpirationList = ref(filteredOrders.value.slice(0, 10));

  const totalPages = computed(() => {
    if (filteredOrders.value.length > 10 && filteredOrders.value.length % 10 != 0) {
      return Math.floor(filteredOrders.value.length / 10) + 1;
    } else {
      return filteredOrders.value.length / 10;
    }
  });

  const showPoDetails = (item: IUserOrders) => {
    store.dispatch(CHANGE_PO_DETAILS_VIEW, true);
    selectedPOName.value = item.ordertext;
    selectedItem.value = item;
  };

  const handleRenew = async (item: IUserOrders) => {
    const stringifiedData = JSON.stringify(item);
    localStorage.setItem("archiveItemEdit", stringifiedData);
    store.commit("setRenewOrderItem", item);
    store.commit("setIsRenewingPr", true);
    setTimeout(() => {
      router.push("/renew-purchase-request");
    }, 500);
  };

  watch([filteredOrders], () => {
    paginatedList.value = orders.value?.slice(0, 10);
    paginatedPOExpirationList.value = filteredOrders.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IUserOrders>) => {
    paginatedPOExpirationList.value = filteredList;
    paginatedList.value = filteredList;
  };
</script>
